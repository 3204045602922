const updateValue = (value) => {
  switch (value) {
    case "YES":
      return "Yes";
    case "NO":
      return "No";
    case "NA":
      return "NA";
    default:
      return value;
  }
};
export const generateScore = (patient) => {
  const genScoreData = new FormData();
  const YES = "1";
  const NO = "0";
  const NORMAL = "2";
  const OTHER = "3";
  console.log('babyInvScore',patient)
if(patient.babyGITDataScore?.abdominal_dystension){
  if (patient.babyGITDataScore?.abdominal_dystension == "YES") {
    genScoreData.append("Abdominal Dystension", YES);
  }
  if (patient.babyGITDataScore?.abdominal_dystension == "NO") {
    genScoreData.append("Abdominal Dystension", NO);
  }
  if (patient.babyGITDataScore?.abdominal_dystension == "NA") {
    genScoreData.append("Abdominal Dystension", NO);
  }
}
else{
    genScoreData.append("Abdominal Dystension", NO);
}
  if(patient.babyInvDataScore?.absolute_neutrophil_count == "NA"){
    genScoreData.append(
      "Absolute Neutrophil Count_value","18"
    );
  }
  else{
    if( patient.babyInvDataScore?.absolute_neutrophil_count_unit == "mL")
      {
        const abnc_Value = "1000"* patient.babyInvDataScore?.absolute_neutrophil_count;
        genScoreData.append(
          "Absolute Neutrophil Count_value",
          abnc_Value
            ? abnc_Value
            : "18"
        );
      }
      else{
  genScoreData.append(
    "Absolute Neutrophil Count_value",
    patient.babyInvDataScore?.absolute_neutrophil_count
      ? patient.babyInvDataScore?.absolute_neutrophil_count
      : "18"
  );
}
  }

if(patient.babyCNSDataScore?.af_bulge){ 
  if (patient.babyCNSDataScore?.af_bulge == "YES") {
    genScoreData.append("Af Bulge", YES);
  }
  if (patient.babyCNSDataScore?.af_bulge == "NO") {
    genScoreData.append("Af Bulge", NO);
  }
  if (patient.babyCNSDataScore?.af_bulge == "NA") {
    genScoreData.append("Af Bulge", NO);
  }
}
else{
  genScoreData.append("Af Bulge", NO);
}
if(patient.babyAppearanceScore?.baby_appearance){
  if (patient.babyAppearanceScore?.baby_appearance == "Dull") {
    genScoreData.append("Baby Appearance", NO);
  }
  if (patient.babyAppearanceScore?.baby_appearance == "Lethargic") {
    genScoreData.append("Baby Appearance", YES);
  }
  if (patient.babyAppearanceScore?.baby_appearance == "Normal") {
    genScoreData.append("Baby Appearance", NORMAL);
  }
  if (patient.babyAppearanceScore?.baby_appearance == "NA") {
    genScoreData.append("Baby Appearance", NO);
  }
}
else{
    genScoreData.append("Baby Appearance", NO);
}

if  (patient.babyAppearanceScore?.baby_cry_sound){
  if (patient.babyAppearanceScore?.baby_cry_sound == "High Pitch") {
    genScoreData.append("Baby Cry Sound", YES);
  }
  if (patient.babyAppearanceScore?.baby_cry_sound == "Low Pitch") {
    genScoreData.append("Baby Cry Sound", NO);
  }
  if (patient.babyAppearanceScore?.baby_cry_sound == "Normal") {
    genScoreData.append("Baby Cry Sound", NORMAL);
  }
  if (patient.babyAppearanceScore?.baby_cry_sound == "Not Cried") {
    genScoreData.append("Baby Cry Sound", OTHER);
  }
  if (patient.babyAppearanceScore?.baby_cry_sound == "NA") {
    genScoreData.append("Baby Cry Sound", NORMAL);
  }
  }
  else{
    genScoreData.append("Baby Cry Sound", NORMAL);
  }
  
  if(patient.babyAppearanceScore?.baby_feeding_status){
    if (patient.babyAppearanceScore?.baby_feeding_status == "Poor") {
      genScoreData.append("Baby Feeding Status", NORMAL);
    }
    if (patient.babyAppearanceScore?.baby_feeding_status == "Normal") {
      genScoreData.append("Baby Feeding Status", NO);
    }
    if (patient.babyAppearanceScore?.baby_feeding_status == "No Feeding") {
      genScoreData.append("Baby Feeding Status", YES);
    }
    if (patient.babyAppearanceScore?.baby_feeding_status == "NA") {
      genScoreData.append("Baby Feeding Status", YES);
    }
  }
  else{
      genScoreData.append("Baby Feeding Status", YES);
  }

  if(patient.babyCVDataScore?.baby_blood_pressure_lower_limb == "NA"){
    genScoreData.append(
      "Baby Blood Pressure Lower Limb_value","48.5"
    );
  }
  else{
  genScoreData.append(
    "Baby Blood Pressure Lower Limb_value",
    patient.babyCVDataScore?.baby_blood_pressure_lower_limb
      ? patient.babyCVDataScore?.baby_blood_pressure_lower_limb
      : "48.5"
  );
}
  if(patient.babyCVDataScore?.baby_blood_pressure_upper_limb == "NA"){
    genScoreData.append(
      "Baby Blood Pressure Upper Limb_value","56"
    );
  }
  else{
  genScoreData.append(
    "Baby Blood Pressure Upper Limb_value",
    patient.babyCVDataScore?.baby_blood_pressure_upper_limb
      ? patient.babyCVDataScore?.baby_blood_pressure_upper_limb
      : "56"
  );
}
  if(patient.babyCVDataScore?.baby_blood_pressure_mean_arterial_bp == "NA"){
    genScoreData.append(
      "Baby Blood Pressure Mean Arterial Bp_value","58.5"
    );
  }
  else{
  genScoreData.append(
    "Baby Blood Pressure Mean Arterial Bp_value",
    patient.babyCVDataScore?.baby_blood_pressure_mean_arterial_bp
      ? patient.babyCVDataScore?.baby_blood_pressure_mean_arterial_bp
      : "58.5"
  );
  }
  if(patient.BabyProfileScore?.baby_gestational_age == "NA"){
    genScoreData.append(
      "Baby Gestational Age_value","36.00"
    );
  }
  else{
  genScoreData.append(
    "Baby Gestational Age_value",
    patient.BabyProfileScore?.baby_gestational_age
      ? patient.BabyProfileScore?.baby_gestational_age
      : "36.00"
  );
}
  if(patient.babyInvDataScore?.baby_haemoglobin_levels == "NA"){
    genScoreData.append("Baby Haemoglobin Levels_value","14.7");
  }
  else{ 
  if( patient.babyInvDataScore?.baby_haemoglobin_levels_unit == "g/L")
    {
      const BHL_Value = patient.babyInvDataScore?.baby_haemoglobin_levels * "0.10";
      genScoreData.append(
        "Baby Haemoglobin Levels_value",
        BHL_Value
          ? BHL_Value
          : "14.7"
      );
    }
    else{
  genScoreData.append(
    "Baby Haemoglobin Levels_value",
    patient.babyInvDataScore?.baby_haemoglobin_levels
      ? patient.babyInvDataScore?.baby_haemoglobin_levels
      : "14.7"
  );
  }
}
if(patient.babyAppearanceScore?.baby_jaundice){
  if (patient.babyAppearanceScore?.baby_jaundice == "YES") {
    genScoreData.append("Baby Jaundice", YES);
  }
  if (patient.babyAppearanceScore?.baby_jaundice == "NO") {
    genScoreData.append("Baby Jaundice", NO);
  }
  if (patient.babyAppearanceScore?.baby_jaundice == "NA") {
    genScoreData.append("Baby Jaundice", NO);
  }
}
else{
  genScoreData.append("Baby Jaundice", NO);
}

if(patient.babyCNSDataScore?.baby_movement){
  if (patient.babyCNSDataScore?.baby_movement == "YES") {
    genScoreData.append("Baby Movement", YES);
  }
  if (patient.babyCNSDataScore?.baby_movement == "NO") {
    genScoreData.append("Baby Movement", NO);
  }

  if (patient.babyCNSDataScore?.baby_movement == "NA") {
    genScoreData.append("Baby Movement", NO);
  }
}
else{
  genScoreData.append("Baby Movement", NO);
}
  if(patient.babyInvDataScore?.baby_c_reactive_protien_levels == "NA"){
    genScoreData.append(
      "Baby Reactive Protien Levels_value","6"
    );
  }
  else{
    
  if( patient.babyInvDataScore?.baby_c_reactive_protien_levels_unit == "mg/dL")
    {
      const CRPL_Value = patient.babyInvDataScore?.baby_c_reactive_protien_levels * "10";
      genScoreData.append(
        "Baby Reactive Protien Levels_value",
        CRPL_Value
          ? CRPL_Value
          : "6"
      );
    }
    else{
    genScoreData.append(
      "Baby Reactive Protien Levels_value",
      patient.babyInvDataScore?.baby_c_reactive_protien_levels
        ? patient.babyInvDataScore?.baby_c_reactive_protien_levels
        : "6"
    );
  }
  }
  if(patient.babyAppearanceScore?.baby_skin_colour){
  if (patient.babyAppearanceScore?.baby_skin_colour == "Central Cyanosis") {
    genScoreData.append("Baby Skin Colour", NO);
  }
  if (patient.babyAppearanceScore?.baby_skin_colour == "Peripheral Duskiness") {
    genScoreData.append("Baby Skin Colour", OTHER);
  }
  if (patient.babyAppearanceScore?.baby_skin_colour == "Acrocyanosis") {
    genScoreData.append("Baby Skin Colour", YES);
  }
  if (patient.babyAppearanceScore?.baby_skin_colour == "Pink") {
    genScoreData.append("Baby Skin Colour", "5");
  }
  if (patient.babyAppearanceScore?.baby_skin_colour == "Pale") {
    genScoreData.append("Baby Skin Colour", NORMAL);
  }
  if (patient.babyAppearanceScore?.baby_skin_colour == "NA") {
    genScoreData.append("Baby Skin Colour", "5");
  }
}
else{
  genScoreData.append("Baby Skin Colour", "5");
}
  
  if(patient.BabyProfileScore?.baby_weight_at_birth == "NA"){
    genScoreData.append(
      "Baby Weight At Birth_value","2.30"
    );
  }
  else{
  genScoreData.append(
    "Baby Weight At Birth_value",
    patient.BabyProfileScore?.baby_weight_at_birth
      ? patient.BabyProfileScore?.baby_weight_at_birth
      : "2.30"
  );
}
  if(patient.babyInvDataScore?.bilirubin_levels == "NA"){
    genScoreData.append(
      "Bilirubin Levels_value","8.87"
    );
  }
  else{
    
  if( patient.babyInvDataScore?.bilirubin_unit == "mg/dL")
    {
      const BLR_Value = "0.0555"* patient.babyInvDataScore?.bilirubin_levels;
      genScoreData.append(
        "Bilirubin Levels_value",
        BLR_Value
          ? BLR_Value
          : "8.87"
      );  
    }
    else{
  
  genScoreData.append(
    "Bilirubin Levels_value",
    patient.babyInvDataScore?.bilirubin_levels
      ? patient.babyInvDataScore?.bilirubin_levels
      : "8.87"
  );
}
  }
  if(patient.babyInvDataScore?.calcium == "NA"){
    genScoreData.append(
      "Calcium_value","1.45"
    );
  }
  else{
    
  if( patient.babyInvDataScore?.calcium_unit == "mg/dL")
    {
      const calcium_Value = "0.0555"* patient.babyInvDataScore?.calcium;
      genScoreData.append(
        "Calcium_value",
        calcium_Value
          ? calcium_Value
          : "1.45"
      );
    }
    else{
  genScoreData.append(
    "Calcium_value",
    patient.babyInvDataScore?.calcium ? patient.babyInvDataScore?.calcium : "1.45"
  );
  }
}
  if(patient.babyInvDataScore?.creatinine == "NA"){
    genScoreData.append(
      "Creatinine_value","0.6"
    );
  }
  else{
    
  if( patient.babyInvDataScore?.creatinine_unit == "mg/dL")
    {
      const creatinine_Value = "0.0555"* patient.babyInvDataScore?.creatinine;
      genScoreData.append(
        "Creatinine_value",
        creatinine_Value
          ? creatinine_Value
          : "0.6"
      );
    }
    else{
  genScoreData.append(
    "Creatinine_value",
    patient.babyInvDataScore?.creatinine ? patient.babyInvDataScore?.creatinine : "0.6"
  );
}
  }

  if (patient.babyInvDataScore?.sodium == "NA") {
    genScoreData.append(
      "Sodium_value","137.5"
    );
  }
  else{
    if(patient.babyInvDataScore?.Sodium_unit == "mg/dL")
      {
        const sodium_Value = "0.0555"*patient.babyInvDataScore?.sodium;
        genScoreData.append(
          "Sodium_value",
          sodium_Value
            ? sodium_Value
            : "137.5"
        );
      }
      else{
      genScoreData.append(
        "Sodium_value",
      patient.babyInvDataScore?.sodium
        ?patient.babyInvDataScore?.sodium : "137.5"
      );
    }
  }

  
  if (patient.babyInvDataScore?.chlorine == "NA") {
    genScoreData.append(
      "Chlorine_value","137.5"
    );
  }
  else{
    if(patient.babyInvDataScore?.chlorine_unit == "mg/dL")
      {
        const Chlorine_value = "0.0555"*patient.babyInvDataScore?.chlorine;
        genScoreData.append(
          "Chlorine_value",
          Chlorine_value
            ? Chlorine_value
            : "137.5"
        );
      }
      else{
      genScoreData.append(
        "Chlorine_value",
      patient.babyInvDataScore?.chlorine
        ?patient.babyInvDataScore?.chlorine : "137.5"
      );
    }
  }

  if(patient.babyInvDataScore?.potassium == "NA"){
    genScoreData.append(
      "Potassium_value","4.8"
    );
  }
  else{
    
  
  if( patient.babyInvDataScore?.potassium_unit == "mg/dL")
    {
      const potassium_Value = "0.0555"* patient.babyInvDataScore?.potassium;
      genScoreData.append(
        "Potassium_value",
        potassium_Value
          ? potassium_Value
          : "4.8"
      );
    }
    else{
  genScoreData.append(
    "Potassium_value",
    patient.babyInvDataScore?.potassium ? patient.babyInvDataScore?.potassium : "4.8"
  );
}
  }
  
  if(patient.babyInvDataScore?.urea == "NA"){
    genScoreData.append(
      "Urea_value","26.4"
    );
  }
  else{
    
  if( patient.babyInvDataScore?.urea_unit == "mg/dL")
    {
      const urea_Value = "0.0555"* patient.babyInvDataScore?.urea;
      genScoreData.append(
        "Urea_value",
        urea_Value
          ? urea_Value
          : "26.4"
      );
    }
    else{
  genScoreData.append(
    "Urea_value",
    patient.babyInvDataScore?.urea ? patient.babyInvDataScore?.urea : "26.4"
  );
  }
  }
if(patient.babyAppearanceScore?.breast_feeding_initiation){
  if (patient.babyAppearanceScore?.breast_feeding_initiation == "YES") {
    genScoreData.append("Breast Feeding Initiation", YES);
  }
  if (patient.babyAppearanceScore?.breast_feeding_initiation == "NO") {
    genScoreData.append("Breast Feeding Initiation", NO);
  }
  if (patient.babyAppearanceScore?.breast_feeding_initiation == "NA") {
    genScoreData.append("Breast Feeding Initiation", YES);
  }
}
else{
  genScoreData.append("Breast Feeding Initiation", YES);
}
if(patient.babyCVDataScore?.capillary_refill_unit){
  if (patient.babyCVDataScore?.capillary_refill_unit == "> 3 Sec") {
    genScoreData.append("Capillary Refill Unit", YES);
  }
  if (patient.babyCVDataScore?.capillary_refill_unit == "< 3 Sec") {
    genScoreData.append("Capillary Refill Unit", NO);
  }
  if (patient.babyCVDataScore?.capillary_refill_unit == "= 3 Sec") {
    genScoreData.append("Capillary Refill Unit", YES);
  }
  if (patient.babyCVDataScore?.capillary_refill_unit == "= 0 Sec") {
    genScoreData.append("Capillary Refill Unit", NO);
  }
  if (patient.babyCVDataScore?.capillary_refill_unit == "NA") {
    genScoreData.append("Capillary Refill Unit", NO);
  }
}
else{
  genScoreData.append("Capillary Refill Unit", NO);
}
  if(patient.babyRespiratoryDataScore?.breathing_rate == "NA"){
    genScoreData.append(
      "Breathing Rate_value","43.17"
    );
  }
  else{
    console.log('kkkkkkkkkk',patient.babyRespiratoryDataScore?.breathing_rate)
  genScoreData.append(
    "Breathing Rate_value",
    patient.babyRespiratoryDataScore?.breathing_rate
      ? patient.babyRespiratoryDataScore?.breathing_rate
      : "43.17"
  );
}
  if(patient.babyInvDataScore?.baby_blood_glucose == "NA"){
    genScoreData.append(
      "Baby Blood Glucose_value","18.80"
    );
  }
  else{
    
  if( patient.babyInvDataScore?.baby_blood_glucose_unit == "mg/dL")
    {
      const BBGC_Value = "0.0555"* patient.babyInvDataScore?.baby_blood_glucose;
      genScoreData.append(
        "Baby Blood Glucose_value",
        BBGC_Value
          ? BBGC_Value
          : "18.80"
      );
    }
    else{
  genScoreData.append(
    "Baby Blood Glucose_value",
    patient.babyInvDataScore?.baby_blood_glucose
      ? patient.babyInvDataScore?.baby_blood_glucose
      : "18.80"
  );
}
  }
if(patient.motherProfileScore?.delayed_cord_clamping){
  if (patient.motherProfileScore?.delayed_cord_clamping == "YES") {
    genScoreData.append("Delayed Cord Clamping", YES);
  }
  if (patient.motherProfileScore?.delayed_cord_clamping == "NO") {
    genScoreData.append("Delayed Cord Clamping", NO);
  }
  if (patient.motherProfileScore?.delayed_cord_clamping == "NA") {
    genScoreData.append("Delayed Cord Clamping", NO);
  }
}
else{
  genScoreData.append("Delayed Cord Clamping", NO);
}
if(patient.babyRespiratoryDataScore?.fast_breathing){
if (patient.babyRespiratoryDataScore?.fast_breathing == "YES") {
    genScoreData.append("Fast Breathing", YES);
  }
  if (patient.babyRespiratoryDataScore?.fast_breathing == "NO") {
    genScoreData.append("Fast Breathing", NO);
  }
  if (patient.babyRespiratoryDataScore?.fast_breathing == "NA") {
    genScoreData.append("Fast Breathing", NO);
  }
}
else{
  genScoreData.append("Fast Breathing", NO);
}
  if(patient.babyGITDataScore?.frequency_of_stools == "NA"){
    genScoreData.append(
      "Frequency Of Stools_value","2.5"
    );
  }
  else{
  genScoreData.append(
    "Frequency Of Stools_value",
    patient.babyGITDataScore?.frequency_of_stools
      ? patient.babyGITDataScore?.frequency_of_stools
      : "2.5"
  );
}
if(patient.babyRespiratoryDataScore?.groaning){
  if (patient.babyRespiratoryDataScore?.groaning == "YES") {
    genScoreData.append("Groaning", YES);
  }
  if (patient.babyRespiratoryDataScore?.groaning == "NO") {
    genScoreData.append("Groaning", NO);
  }
  if (patient.babyRespiratoryDataScore?.groaning == "NA") {
    genScoreData.append("Groaning", NO);
  }
}
else{
  genScoreData.append("Groaning", NO);
}
if(patient.babyRespiratoryDataScore?.grunting){
  if (patient.babyRespiratoryDataScore?.grunting == "YES") {
    genScoreData.append("Grunting", YES);
  }
  if (patient.babyRespiratoryDataScore?.grunting == "NO") {
    genScoreData.append("Grunting", NO);
  }
  if (patient.babyRespiratoryDataScore?.grunting == "NA") {
    genScoreData.append("Grunting", NO);
  }
}
else{
  genScoreData.append("Grunting", NO);
}
  
  if(patient.babyCVDataScore?.heart_rate == "NA"){
    genScoreData.append(
      "Heart Rate_value","141.00"
    );
  }
  else{
  genScoreData.append(
    "Heart Rate_value",
    patient.babyCVDataScore?.heart_rate
     ? patient.babyCVDataScore?.heart_rate : "141.00"
  );
}
if(patient.motherProfileScore?.leaking_pv){
  if (patient.motherProfileScore?.leaking_pv == "YES") {
    genScoreData.append("Leaking pv", YES);
  }
  if (patient.motherProfileScore?.leaking_pv == "NO") {
    genScoreData.append("Leaking pv", NO);
  }
  if (patient.motherProfileScore?.leaking_pv == "NA") {
    genScoreData.append("Leaking pv", NO);
  }
}
else{
  genScoreData.append("Leaking pv", NO);
}
if(patient.motherProfileScore?.maternal_diabetes){
  if (patient.motherProfileScore?.maternal_diabetes == "YES") {
    genScoreData.append("Maternal Diabetes", YES);
  }
  if (patient.motherProfileScore?.maternal_diabetes == "NO") {
    genScoreData.append("Maternal Diabetes", NO);
  }
  if (patient.motherProfileScore?.maternal_diabetes == "NA") {
    genScoreData.append("Maternal Diabetes", NO);
  }
}
else{
  genScoreData.append("Maternal Diabetes", NO);
}
  if(patient.babyRespiratoryDataScore?.oxygen_saturation == "NA"){
    genScoreData.append(
      "Oxygen Saturation_value","96.66"
    );
  }
  else{
  genScoreData.append(
    "Oxygen Saturation_value",
    patient.babyRespiratoryDataScore?.oxygen_saturation
      ? patient.babyRespiratoryDataScore?.oxygen_saturation
      : "96.66"
  );
}
if(patient.motherProfileScore?.rupture_of_membranes_rom_one){
  if (patient.motherProfileScore?.rupture_of_membranes_rom_one == "PROM") {
    genScoreData.append("Rupture Of Membranes Rom", YES);
  }
  if (patient.motherProfileScore?.rupture_of_membranes_rom_one == "SROM") {
    genScoreData.append("Rupture Of Membranes Rom", NORMAL);
  }
  if (patient.motherProfileScore?.rupture_of_membranes_rom_one == "AROM") {
    genScoreData.append("Rupture Of Membranes Rom", NO);
  }
  if (patient.motherProfileScore?.rupture_of_membranes_rom_one == "NA") {
    genScoreData.append("Rupture Of Membranes Rom", NO);
  }
}
else{
  genScoreData.append("Rupture Of Membranes Rom", NO);
}
/* if(patient.getMotherProfile?.rupture_of_membranes_rom){
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "YES") {
    genScoreData.append("Rupture Of Membranes Rom_Yes", YES);
    genScoreData.append("Rupture Of Membranes Rom_No", NO);
  }
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "NO") {
    genScoreData.append("Rupture Of Membranes Rom_Yes", NO);
    genScoreData.append("Rupture Of Membranes Rom_No", YES);
  }
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "NA") {
    genScoreData.append("Rupture Of Membranes Rom_Yes", NO);
    genScoreData.append("Rupture Of Membranes Rom_No", NO);
  }
}
else{
  genScoreData.append("Rupture Of Membranes Rom_Yes", NO);
  genScoreData.append("Rupture Of Membranes Rom_No", NO);
} */
if(patient.babyCNSDataScore?.seizures){
  if (patient.babyCNSDataScore?.seizures == "YES") {
    genScoreData.append("Seizures", YES);
  }
  if (patient.babyCNSDataScore?.seizures == "NO") {
    genScoreData.append("Seizures", NO);
  }
  if (patient.babyCNSDataScore?.seizures == "NA") {
    genScoreData.append("Seizures", NO);
  }
}
else{
  genScoreData.append("Seizures", NO);
}
  
  if(patient.babyInvDataScore?.thrombocytopenia == "NA"){
    genScoreData.append(
      "Thrombocytopeni_value","191000"
    );
  }
  else{
  genScoreData.append(
    "Thrombocytopeni_value",
    patient.babyInvDataScore?.thrombocytopenia
      ? patient.babyInvDataScore?.thrombocytopenia
      : "191000"
  );
}
  if(patient.babyInvDataScore?.total_leucocute_count == "NA"){
    genScoreData.append(
      "Total Leucocute Count_value","12300"
    );
  }
  else{
  genScoreData.append(
    "Total Leucocute Count_value",
    patient.babyInvDataScore?.total_leucocute_count
      ? patient.babyInvDataScore?.total_leucocute_count
      : "12300"
  );
}

console.log('kkkkkkkkkkkkkkfvhfdgfd',genScoreData)
  return genScoreData;
};

export const NonInvasiveScoreData = (patient) => {
  const YES = "1";
  const NO = "0";
  const Normal = "2"
  const Other = "3"
  const NonInvasiveScore = new FormData();

  console.log('lllllllllllll',NonInvasiveScore,patient)
if(patient.babyHealthParameters?.abdominal_dystension){
  if (patient.babyHealthParameters?.abdominal_dystension == "YES") {
    NonInvasiveScore.append("Abdominal Dystension", YES);
  }
  else if (patient.babyHealthParameters?.abdominal_dystension == "NO") {
    NonInvasiveScore.append("Abdominal Dystension", NO);
  }
  else if (patient.babyHealthParameters?.abdominal_dystension == "NA") {
    NonInvasiveScore.append("Abdominal Dystension", NO);
  }
}
  else {
    NonInvasiveScore.append("Abdominal Dystension", NO);
  }
 /*  if(patient.babyHealthParameters?.absolute_neutrophil_count == "NA"){
    NonInvasiveScore.append(
      "Absolute Neutrophil Count_value","18.00"
    );
  }
  else{
    if( patient.babyHealthParameters?.absolute_neutrophil_count_unit == "mL")
      {
        const abnc_Value = "1000"* patient.babyHealthParameters?.absolute_neutrophil_count;
        NonInvasiveScore.append(
          "Absolute Neutrophil Count_value",
          abnc_Value
            ? abnc_Value
            : "18.00"
        );
      }
      else{
    NonInvasiveScore.append(
      "Absolute Neutrophil Count_value",
      patient.babyHealthParameters?.absolute_neutrophil_count
        ? patient.babyHealthParameters?.absolute_neutrophil_count
        : "18.00"
    );
    }
} */
if(patient.babyHealthParameters?.af_bulge){
  if (patient.babyHealthParameters?.af_bulge == "YES") {
    NonInvasiveScore.append("Af Bulge", YES);
  }
  else if (patient.babyHealthParameters?.af_bulge == "NO") {
    NonInvasiveScore.append("Af Bulge", NO);
  }
  else if (patient.babyHealthParameters?.af_bulge == "NA") {
    NonInvasiveScore.append("Af Bulge", NO);
  }
}
  else {
    NonInvasiveScore.append("Af Bulge", NO);
  }
  if (patient.babyHealthParameters?.baby_appearance){
  if (patient.babyHealthParameters?.baby_appearance == "Dull") {
    NonInvasiveScore.append("Baby Appearance", YES);
  }
  else if (patient.babyHealthParameters?.baby_appearance == "Lethargic") {
    NonInvasiveScore.append("Baby Appearance", NO);
  }
  else if (patient.babyHealthParameters?.baby_appearance == "Normal") {
    NonInvasiveScore.append("Baby Appearance", Normal);
  }
  else if (patient.babyHealthParameters?.baby_appearance == "NA") {
    NonInvasiveScore.append("Baby Appearance", YES);
  }
}
  else {
    NonInvasiveScore.append("Baby Appearance", YES);
  }
  
if(patient.babyHealthParameters?.baby_cry_sound){
  if (patient.babyHealthParameters?.baby_cry_sound == "High Pitch") {
    NonInvasiveScore.append("Baby Cry Sound", YES);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "Low Pitch") {
    NonInvasiveScore.append("Baby Cry Sound", NO);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "Normal") {
    NonInvasiveScore.append("Baby Cry Sound", Normal);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "Not Cried") {
    NonInvasiveScore.append("Baby Cry Sound", Other);
  }
  else if (patient.babyHealthParameters?.baby_cry_sound == "NA") {
    NonInvasiveScore.append("Baby Cry Sound", Normal);
  }
}
else {
  NonInvasiveScore.append("Baby Cry Sound", Normal);
}
if(patient.babyHealthParameters?.baby_feeding_status){
  if (patient.babyHealthParameters?.baby_feeding_status == "Poor") {
    NonInvasiveScore.append("Baby Feeding Status", Normal);
  }
  else if (patient.babyHealthParameters?.baby_feeding_status == "Normal") {
    NonInvasiveScore.append("Baby Feeding Status", NO);
  }
  else if (patient.babyHealthParameters?.baby_feeding_status == "No Feeding") {
    NonInvasiveScore.append("Baby Feeding Status", YES);
  }
  else if (patient.babyHealthParameters?.baby_feeding_status == "NA") {
    NonInvasiveScore.append("Baby Feeding Status", NO);
  }
}
else{
    NonInvasiveScore.append("Baby Feeding Status", NO);
  
}
if(patient.getBabyProfile?.baby_gestational_age == "NA"){
  NonInvasiveScore.append(
    "Baby Gestational Age_value","36.00"
  );
}
else{
  NonInvasiveScore.append(
    "Baby Gestational Age_value",
    patient.getBabyProfile?.baby_gestational_age
      ? patient.getBabyProfile?.baby_gestational_age
      : "36.00"
  );
}
if (patient.babyHealthParameters?.baby_jaundice){
  if (patient.babyHealthParameters?.baby_jaundice == "YES") {
    NonInvasiveScore.append("Baby Jaundice", YES);
  }
  else if (patient.babyHealthParameters?.baby_jaundice == "NO") {
    NonInvasiveScore.append("Baby Jaundice", NO);
  }
  else if (patient.babyHealthParameters?.baby_jaundice == "NA") {
    NonInvasiveScore.append("Baby Jaundice", NO);
  }
}
  else  {
    NonInvasiveScore.append("Baby Jaundice", NO);
  }

  if(patient.babyHealthParameters?.baby_movement){
    if (patient.babyHealthParameters?.baby_movement == "YES") {
      NonInvasiveScore.append("Baby Movement", YES);
    }
    else if (patient.babyHealthParameters?.baby_movement == "NO") {
      NonInvasiveScore.append("Baby Movement", NO);
    }
    else if (patient.babyHealthParameters?.baby_movement == "NA") {
      NonInvasiveScore.append("Baby Movement", YES);
    }
  }
  else {
    NonInvasiveScore.append("Baby Movement", YES);
  }
  if(patient.babyHealthParameters?.baby_skin_colour){
  if (patient.babyHealthParameters?.baby_skin_colour == "Central Cyanosis") {
    NonInvasiveScore.append("Baby Skin Colour", NO);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Peripheral Duskiness") {
    NonInvasiveScore.append("Baby Skin Colour", Other);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Acrocyanosis") {
    NonInvasiveScore.append("Baby Skin Colour", YES);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Pink") {
    NonInvasiveScore.append("Baby Skin Colour", "4");
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "Pale") {
    NonInvasiveScore.append("Baby Skin Colour", Normal);
  }
  else if (patient.babyHealthParameters?.baby_skin_colour == "NA") {
    NonInvasiveScore.append("Baby Skin Colour", "4");
  }
  }
  else {
    NonInvasiveScore.append("Baby Skin Colour", "4");
  }
  if(patient.getBabyProfile?.baby_weight_at_birth == "NA"){
    NonInvasiveScore.append(
      "Baby Weight At Birth_value","2.30"
    );
  }
  else{
  NonInvasiveScore.append(
    "Baby Weight At Birth_value",
    patient.getBabyProfile?.baby_weight_at_birth
      ? patient.getBabyProfile?.baby_weight_at_birth
      : "2.30"
  );
  }
  if (patient.babyHealthParameters?.breast_feeding_initiation){
    if (patient.babyHealthParameters?.breast_feeding_initiation == "YES") {
      NonInvasiveScore.append("Breast Feeding Initiation", YES);
    }
    else if (patient.babyHealthParameters?.breast_feeding_initiation == "NO") {
      NonInvasiveScore.append("Breast Feeding Initiation", NO);
    }
    else if (patient.babyHealthParameters?.breast_feeding_initiation == "NA") {
      NonInvasiveScore.append("Breast Feeding Initiation", YES);
    }
  }
  else{
    NonInvasiveScore.append("Breast Feeding Initiation", YES);
  }

  if(patient.babyHealthParameters?.breathing_rate == "NA"){
    NonInvasiveScore.append(
      "Breathing Rate_value","51.00"
    );
  }
  else{
  NonInvasiveScore.append(
    "Breathing Rate_value",
    patient.babyHealthParameters?.breathing_rate
      ? patient.babyHealthParameters?.breathing_rate
      : "51.00"
  );
}
if(patient.babyHealthParameters?.capillary_refill_unit){
  if (patient.babyHealthParameters?.capillary_refill_unit == "> 3 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit", YES);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "< 3 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit", NO)
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "= 3 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit", NO);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "= 0 Sec") {
    NonInvasiveScore.append("Capillary Refill Unit", NO);
  }
  else if (patient.babyHealthParameters?.capillary_refill_unit == "NA") {
    NonInvasiveScore.append("Capillary Refill Unit", NO);
  }
}
else {
  NonInvasiveScore.append("Capillary Refill Unit", NO);
}
if (patient.getMotherProfile?.delayed_cord_clamping) {
  if (patient.getMotherProfile?.delayed_cord_clamping == "YES") {
    NonInvasiveScore.append("Delayed Cord Clamping", YES);
  }
  else if (patient.getMotherProfile?.delayed_cord_clamping == "NO") {
    NonInvasiveScore.append("Delayed Cord Clamping", NO);
  }
  else if (patient.getMotherProfile?.delayed_cord_clamping == "NA") {
    NonInvasiveScore.append("Delayed Cord Clamping", NO);
  }
  else if (patient.getMotherProfile?.delayed_cord_clamping == "") {
    NonInvasiveScore.append("Delayed Cord Clamping", NO);
  }
}
else{
  NonInvasiveScore.append("Delayed Cord Clamping", NO);
}
if(patient.babyHealthParameters?.fast_breathing){
  if (patient.babyHealthParameters?.fast_breathing == "YES") {
    NonInvasiveScore.append("Fast Breathing", YES);
  }
  else if (patient.babyHealthParameters?.fast_breathing == "NO") {
    NonInvasiveScore.append("Fast Breathing", NO);
  }
  else if (patient.babyHealthParameters?.fast_breathing == "NA") {
    NonInvasiveScore.append("Fast Breathing", NO);
  }
}
else{
  NonInvasiveScore.append("Fast Breathing", NO);
}
  if(patient.babyHealthParameters?.frequency_of_stools == "NA"){
    NonInvasiveScore.append(
      "Frequency Of Stools_value","2.00"
    );
  }
  else{
  NonInvasiveScore.append(
    "Frequency Of Stools_value",
    patient.babyHealthParameters?.frequency_of_stools
      ? patient.babyHealthParameters?.frequency_of_stools
      : "2.00"
  );
}
if (patient.babyHealthParameters?.groaning){
  if (patient.babyHealthParameters?.groaning == "YES") {
    NonInvasiveScore.append("Groaning", YES);
  }
  else if (patient.babyHealthParameters?.groaning == "NO") {
    NonInvasiveScore.append("Groaning", NO);
  }
  else if (patient.babyHealthParameters?.groaning == "NA") {
    NonInvasiveScore.append("Groaning", NO);
  }
}
else{
  NonInvasiveScore.append("Groaning", NO);
}
if(patient.babyHealthParameters?.grunting){
  if (patient.babyHealthParameters?.grunting == "YES") {
    NonInvasiveScore.append("Grunting", YES);
  }
  else if (patient.babyHealthParameters?.grunting == "NO") {
    NonInvasiveScore.append("Grunting", NO);
  }
  else if (patient.babyHealthParameters?.grunting == "NA") {
    NonInvasiveScore.append("Grunting", NO);
  }
}
else{
  NonInvasiveScore.append("Grunting", NO);
}
  if(patient.babyHealthParameters?.heart_rate == "NA"){
    NonInvasiveScore.append(
      "Heart Rate_value","141.00"
    );
  }
  else{
  NonInvasiveScore.append(
    "Heart Rate_value",
    patient.babyHealthParameters?.heart_rate
      ? patient.babyHealthParameters?.heart_rate
      : "141.00"
  );
}
if(patient.getMotherProfile?.leaking_pv){
  if (patient.getMotherProfile?.leaking_pv == "YES") {
    NonInvasiveScore.append("Leaking pv", YES);
  }
  else if (patient.getMotherProfile?.leaking_pv == "NO") {
    NonInvasiveScore.append("Leaking pv", NO);
  }
  else if (patient.getMotherProfile?.leaking_pv == "NA") {
    NonInvasiveScore.append("Leaking pv", NO);
  }
  else if (patient.getMotherProfile?.leaking_pv == "") {
    NonInvasiveScore.append("Leaking pv", NO);
  }
}
  else{
    NonInvasiveScore.append("Leaking pv", NO);
  }
if(patient.getMotherProfile?.maternal_diabetes){
  if (patient.getMotherProfile?.maternal_diabetes == "YES") {
    NonInvasiveScore.append("Maternal Diabetes", YES);
  }
  else if (patient.getMotherProfile?.maternal_diabetes == "NO") {
    NonInvasiveScore.append("Maternal Diabetes", NO);
  }
  else if (patient.getMotherProfile?.maternal_diabetes == "NA") {
    NonInvasiveScore.append("Maternal Diabetes", NO);
  }
}
else{
  NonInvasiveScore.append("Maternal Diabetes", NO);
}
  if(patient.babyHealthParameters?.oxygen_saturation == "NA"){
    NonInvasiveScore.append(
      "Oxygen Saturation_value","96.66"
    );
  }
  else{
  NonInvasiveScore.append(
    "Oxygen Saturation_value",
    patient.babyHealthParameters?.oxygen_saturation
      ? patient.babyHealthParameters?.oxygen_saturation
      : "96.66"
  );
  }
if (patient.getMotherProfile?.rupture_of_membranes_rom_one){
  if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "PROM") {
    NonInvasiveScore.append("Rupture Of Membranes Rom", NO);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "SROM") {
    NonInvasiveScore.append("Rupture Of Membranes Rom", Normal);
  } 
  else if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "AROM") {
    NonInvasiveScore.append("Rupture Of Membranes Rom", YES);
  } 
  else if (patient.getMotherProfile?.rupture_of_membranes_rom_one == "NA") {
    NonInvasiveScore.append("Rupture Of Membranes Rom", NO);
  } 
}
else{
  NonInvasiveScore.append("Rupture Of Membranes Rom", NO);
}
/* if(patient.getMotherProfile?.rupture_of_membranes_rom){
  if (patient.getMotherProfile?.rupture_of_membranes_rom == "YES") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", YES);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom == "NO") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", YES);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom == "NA") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
  }
  else if (patient.getMotherProfile?.rupture_of_membranes_rom == "") {
    NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
    NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
  }
}
else{
  NonInvasiveScore.append("Rupture Of Membranes Rom_Yes", NO);
  NonInvasiveScore.append("Rupture Of Membranes Rom_No", NO);
} */
if(patient.getBabyProfile?.seizures){
  if (patient.getBabyProfile?.seizures == "YES") {
    NonInvasiveScore.append("Seizures", YES);
  }
  else if (patient.getBabyProfile?.seizures == "NO") {
    NonInvasiveScore.append("Seizures", NO);
  }
  else if (patient.getBabyProfile?.seizures == "NA") {
    NonInvasiveScore.append("Seizures", NO);
  }
}
  else{
    NonInvasiveScore.append("Seizures", NO);
  }

  return NonInvasiveScore;
};

export const SemiInvasiveScoreData = (patient) => {
  const YES = "1";
  const NO = "0";
  const SemiInvasiveScore = new FormData();
if(patient.BabySemiInvasive?.abdominal_dystension){
  if (patient.BabySemiInvasive?.abdominal_dystension == "YES") {
    SemiInvasiveScore.append("Abdominal Dystension", YES);
  }
  else if (patient.BabySemiInvasive?.abdominal_dystension == "NO") {
    SemiInvasiveScore.append("Abdominal Dystension", NO);
  }
  else if (patient.BabySemiInvasive?.abdominal_dystension == "NA") {
    SemiInvasiveScore.append("Abdominal Dystension", NO);
  }
}
else{
  SemiInvasiveScore.append("Abdominal Dystension", NO);
}
  if(patient.BabySemiInvasive?.absolute_neutrophil_count == "NA"){
    SemiInvasiveScore.append(
      "Absolute Neutrophil Count_value","18.00"
    );
  }
  else{
    if( patient.BabySemiInvasive?.absolute_neutrophil_count_unit == "mL")
    {
      const abnc_Value = "1000"* patient.BabySemiInvasive?.absolute_neutrophil_count;
      SemiInvasiveScore.append(
        "Absolute Neutrophil Count_value",
        abnc_Value
          ? abnc_Value
          : "18.00"
      );
    }
    else{

    SemiInvasiveScore.append(
    "Absolute Neutrophil Count_value",
    patient.BabySemiInvasive?.absolute_neutrophil_count
      ? patient.BabySemiInvasive?.absolute_neutrophil_count
      : "18.00"
  );
}
}
if(patient.BabySemiInvasive?.baby_blood_glucose == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Glucose_value","79.00"
  );
}
else{
  if( patient.BabySemiInvasive?.baby_blood_glucose_unit == "mg/dL")
    {
      const BBGC_Value = "0.0555"* patient.BabySemiInvasive?.baby_blood_glucose;
      SemiInvasiveScore.append(
        "Baby Blood Glucose_value",
        BBGC_Value
          ? BBGC_Value
          : "79.00"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Baby Blood Glucose_value",
    patient.BabySemiInvasive?.baby_blood_glucose
      ? patient.BabySemiInvasive?.baby_blood_glucose
      : "79.00"
  );
}
}
if(patient.BabySemiInvasive?.breathing_rate == "NA"){
  SemiInvasiveScore.append(
    "Breathing Rate_value","51.00"
  );
}
else{
  SemiInvasiveScore.append(
    "Breathing Rate_value",
    patient.BabySemiInvasive?.breathing_rate
      ? patient.BabySemiInvasive?.breathing_rate
      : "51.00"
  );
}
if(patient.BabySemiInvasive?.baby_blood_pressure_lower_limb == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Pressure Lower Limb_value","54.00"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Blood Pressure Lower Limb_value",
    patient.BabySemiInvasive?.baby_blood_pressure_lower_limb
      ? patient.BabySemiInvasive?.baby_blood_pressure_lower_limb
      : "54.00"
  );
}
if(patient.BabySemiInvasive?.baby_blood_pressure_upper_limb == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Pressure Upper Limb_value","56.00"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Blood Pressure Upper Limb_value",
    patient.BabySemiInvasive?.baby_blood_pressure_upper_limb
      ? patient.BabySemiInvasive?.baby_blood_pressure_upper_limb
      : "56.00"
  );
}

if(patient.BabySemiInvasive?.baby_blood_pressure_mean_arterial_bp == "NA"){
  SemiInvasiveScore.append(
    "Baby Blood Pressure Mean Arterial Bp_value","58.50"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Blood Pressure Mean Arterial Bp_value",
    patient.BabySemiInvasive?.baby_blood_pressure_mean_arterial_bp
      ? patient.BabySemiInvasive?.baby_blood_pressure_mean_arterial_bp
      : "58.50"
  );
}
console.log('patient.getBabyProfile?.baby_gestational_age',patient.getBabyProfile?.baby_gestational_age)
if(patient.getBabyProfile?.baby_gestational_age == "NA"){
  SemiInvasiveScore.append(
    "Baby Gestational Age_value","36.00"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Gestational Age_value",
    patient.getBabyProfile?.baby_gestational_age
      ? patient.getBabyProfile?.baby_gestational_age
      : "36.00"
  );
}

if(patient.BabySemiInvasive?.baby_haemoglobin_levels == "NA"){
  SemiInvasiveScore.append(
    "Baby Haemoglobin Levels_value","14.70"
  );
}
else{
  
  if( patient.BabySemiInvasive?.baby_haemoglobin_levels_unit == "g/L")
    {
      const BHL_Value = patient.BabySemiInvasive?.baby_haemoglobin_levels * "0.10";
      SemiInvasiveScore.append(
        "Baby Haemoglobin Levels_value",
        BHL_Value
          ? BHL_Value
          : "14.70"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Baby Haemoglobin Levels_value",
    patient.BabySemiInvasive?.baby_haemoglobin_levels
      ? patient.BabySemiInvasive?.baby_haemoglobin_levels
      : "14.70"
  );
}
}
if(patient.BabySemiInvasive?.baby_c_reactive_protien_levels == "NA"){
  SemiInvasiveScore.append(
    "Baby Reactive Protien Levels_value","6.00"
  );
}
else{

  if( patient.BabySemiInvasive?.baby_c_reactive_protien_levels_unit == "mg/dL")
    {
      const CRPL_Value = patient.BabySemiInvasive?.baby_c_reactive_protien_levels * "10";
      SemiInvasiveScore.append(
        "Baby Reactive Protien Levels_value",
        CRPL_Value
          ? CRPL_Value
          : "6.00"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Baby Reactive Protien Levels_value",
    patient.BabySemiInvasive?.baby_c_reactive_protien_levels
      ? patient.BabySemiInvasive?.baby_c_reactive_protien_levels
      : "6.00"
  );
}
}

if(patient.getBabyProfile?.baby_weight_at_birth == "NA"){
  SemiInvasiveScore.append(
    "Baby Weight At Birth_value","2.30"
  );
}
else{
  SemiInvasiveScore.append(
    "Baby Weight At Birth_value",
    patient.getBabyProfile?.baby_weight_at_birth
      ? patient.getBabyProfile?.baby_weight_at_birth
      : "2.30"
  );
}
if(patient.BabySemiInvasive?.bilirubin_levels == "NA"){
  SemiInvasiveScore.append(
    "Bilirubin Levels_value","8.87"
  );
}
else{
  
  if( patient.BabySemiInvasive?.bilirubin_unit == "mg/dL")
    {
      const BLR_Value = "0.0555"* patient.BabySemiInvasive?.bilirubin_levels;
      SemiInvasiveScore.append(
        "Bilirubin Levels_value",
        BLR_Value
          ? BLR_Value
          : "8.87"
      );  
    }
    else{
  SemiInvasiveScore.append(
    "Bilirubin Levels_value",
    patient.BabySemiInvasive?.bilirubin_levels
      ? patient.BabySemiInvasive?.bilirubin_levels
      : "8.87"
  );
}
}

if(patient.BabySemiInvasive?.calcium == "NA"){
  SemiInvasiveScore.append(
    "Calcium_value","1.45"
  );
}
else{
  if( patient.BabySemiInvasive?.calcium_unit == "mg/dL")
    {
      const calcium_Value = "0.0555"* patient.BabySemiInvasive?.calcium;
      SemiInvasiveScore.append(
        "Calcium_value",
        calcium_Value
          ? calcium_Value
          : "1.45"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Calcium_value",
    patient.BabySemiInvasive?.calcium
    ? patient.BabySemiInvasive?.calcium : "1.45"
  );
}
}
if(patient.BabySemiInvasive?.creatinine == "NA"){
  SemiInvasiveScore.append(
    "Creatinine_value","0.60"
  );
}
else{
  if( patient.BabySemiInvasive?.creatinine_unit == "mg/dL")
    {
      const creatinine_Value = "0.0555"* patient.BabySemiInvasive?.creatinine;
      SemiInvasiveScore.append(
        "Creatinine_value",
        creatinine_Value
          ? creatinine_Value
          : "0.60"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Creatinine_value",
    patient.BabySemiInvasive?.creatinine
      ? patient.BabySemiInvasive?.creatinine
      : "0.60"
  );
}
}

if(patient.BabySemiInvasive?.chlorine == "NA"){
  SemiInvasiveScore.append(
    "Chlorine_value","104.12"
  );
}
else{
  if( patient.BabySemiInvasive?.chlorine_unit == "mg/dL")
    {
      const chlorine_Value = "0.0555"* patient.BabySemiInvasive?.chlorine;
      SemiInvasiveScore.append(
        "Chlorine_value",
        chlorine_Value
          ? chlorine_Value
          : "104.12"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Chlorine_value",
    patient.BabySemiInvasive?.chlorine
      ? patient.BabySemiInvasive?.chlorine
      : "104.12"
  );
}
}
if(patient.getMotherProfile?.delayed_cord_clamping){
  if (patient.getMotherProfile?.delayed_cord_clamping == "YES") {
    SemiInvasiveScore.append("Delayed Cord Clamping", YES);
  }
  if (patient.getMotherProfile?.delayed_cord_clamping == "NO") {
    SemiInvasiveScore.append("Delayed Cord Clamping", NO);
  }
  if (patient.getMotherProfile?.delayed_cord_clamping == "NA") {
    SemiInvasiveScore.append("Delayed Cord Clamping", NO);
  }
}
else{
  SemiInvasiveScore.append("Delayed Cord Clamping", NO);
}

  if(patient.BabySemiInvasive?.frequency_of_stools == "NA"){
    SemiInvasiveScore.append(
      "Frequency Of Stools_value","2.50"
    );
  }
  else{
  SemiInvasiveScore.append(
    "Frequency Of Stools_value",
    patient.BabySemiInvasive?.frequency_of_stools
      ? patient.BabySemiInvasive?.frequency_of_stools
      : "2.50"
  );
  }
  if(patient.BabySemiInvasive?.groaning){
  if (patient.BabySemiInvasive?.groaning == "YES") {
    SemiInvasiveScore.append("Groaning", YES);
  }
  if (patient.BabySemiInvasive?.groaning == "NO") {
    SemiInvasiveScore.append("Groaning", NO);
  }
  if (patient.BabySemiInvasive?.groaning == "NA") {
    SemiInvasiveScore.append("Groaning", NO);
  }
}
else{
  SemiInvasiveScore.append("Groaning", NO);
}
 /*  if (patient.BabySemiInvasive?.grunting == "YES") {
    SemiInvasiveScore.append("Grunting_Yes", YES);
    SemiInvasiveScore.append("Grunting_No", NO);
  }
  if (patient.BabySemiInvasive?.grunting == "NO") {
    SemiInvasiveScore.append("Grunting_Yes", NO);
    SemiInvasiveScore.append("Grunting_No", YES);
  }
  if (patient.BabySemiInvasive?.grunting == "NA") {
    SemiInvasiveScore.append("Grunting_Yes", NO);
    SemiInvasiveScore.append("Grunting_No", NO);
  } */
  if(patient.BabySemiInvasive?.heart_rate == "NA"){
    SemiInvasiveScore.append(
      "Heart Rate_value","141.00"
    );
  }
  else{
  SemiInvasiveScore.append(
    "Heart Rate_value",
    patient.BabySemiInvasive?.heart_rate
      ? patient.BabySemiInvasive?.heart_rate
      : "141.00"
  );
  }
  if (patient.getMotherProfile?.leaking_pv == "YES") {
    SemiInvasiveScore.append("Leaking pv", YES);
  }
  if (patient.getMotherProfile?.leaking_pv == "NO") {
    SemiInvasiveScore.append("Leaking pv", NO);
  }
  if (patient.getMotherProfile?.leaking_pv == "NA") {
    SemiInvasiveScore.append("Leaking pv", NO);
  }
  if (patient.BabySemiInvasive?.oxygen_saturation == "NA") {
    SemiInvasiveScore.append(
      "Oxygen Saturation_value","96.66"
    );
  }
  else{
  SemiInvasiveScore.append(
    "Oxygen Saturation_value",
    patient.BabySemiInvasive?.oxygen_saturation
      ? patient.BabySemiInvasive?.oxygen_saturation
      : "96.66"
  );
}

if (patient.BabySemiInvasive?.potassium == "NA") {
  SemiInvasiveScore.append(
    "Potassium_value","4.8"
  );
}
else{
  
  if( patient.BabySemiInvasive?.potassium_unit == "mg/dL")
    {
      const potassium_Value = "0.0555"* patient.BabySemiInvasive?.potassium;
      SemiInvasiveScore.append(
        "Potassium_value",
        potassium_Value
          ? potassium_Value
          : "4.8"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Potassium_value",
    patient.BabySemiInvasive?.potassium
      ? patient.BabySemiInvasive?.potassium
      : "4.8"
  );
}
}

if (patient.BabySemiInvasive?.sodium == "NA") {
  SemiInvasiveScore.append(
    "Sodium_value","137.5"
  );
}
else{
  if( patient.BabySemiInvasive?.Sodium_unit == "mg/dL")
    {
      const sodium_Value = "0.0555"* patient.BabySemiInvasive?.sodium;
      SemiInvasiveScore.append(
        "Sodium_value",
        sodium_Value
          ? sodium_Value
          : "137.5"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Sodium_value",
    patient.BabySemiInvasive?.sodium
     ? patient.BabySemiInvasive?.sodium : "137.5"
  );
}
}

if (patient.BabySemiInvasive?.urea == "NA") {
  SemiInvasiveScore.append(
    "Urea_value","26.40"
  );
}
else{
  
  if( patient.BabySemiInvasive?.urea_unit == "mg/dL")
    {
      const urea_Value = "0.0555"* patient.BabySemiInvasive?.urea;
      SemiInvasiveScore.append(
        "Urea_value",
        urea_Value
          ? urea_Value
          : "26.40"
      );
    }
    else{
  SemiInvasiveScore.append(
    "Urea_value",
    patient.BabySemiInvasive?.urea
     ? patient.BabySemiInvasive?.urea : "26.40"
  );
}
}
if (patient.BabySemiInvasive?.thrombocytopenia == "NA") {
  SemiInvasiveScore.append(
    "Thrombocytopeni_value","191000.00"
  );
}
else{
  SemiInvasiveScore.append(
    "Thrombocytopeni_value",
    patient.BabySemiInvasive?.thrombocytopenia
      ? patient.BabySemiInvasive?.thrombocytopenia
      : "191000.00"
  );
}
if (patient.BabySemiInvasive?.total_leucocute_count == "NA") {
  SemiInvasiveScore.append(
    "Total Leucocute Count_value","12300"
  );
}
else{
  SemiInvasiveScore.append(
    "Total Leucocute Count_value",
    patient.BabySemiInvasive?.total_leucocute_count
      ? patient.BabySemiInvasive?.total_leucocute_count
      : "12300"
  );
}
  return SemiInvasiveScore;
};

export const QuickSepsisScore = (patient) => {
  const QuickSepsis = new FormData();
  QuickSepsis.append(
    "baby_weight_at_birth",
    patient.getBabyProfile?.baby_weight_at_birth
      ? patient.getBabyProfile?.baby_weight_at_birth
      : "NA"
  );
  QuickSepsis.append(
    "baby_weight_at_admission",
    patient.BabyProfileScore?.baby_weight_at_admission
      ? patient.BabyProfileScore?.baby_weight_at_admission
      : "NA"
  );
  QuickSepsis.append(
    "baby_gestational_age",
    patient.BabyProfileScore?.baby_gestational_age
      ? patient.BabyProfileScore?.baby_gestational_age
      : "NA"
  );

  QuickSepsis.append(
    "mother_bmi",
    patient.getMotherProfile?.mother_bmi
      ? patient.getMotherProfile?.mother_bmi
      : "NA"
  );

  QuickSepsis.append(
    "mother_age",
    patient.getMotherProfile?.mother_age
      ? patient.getMotherProfile?.mother_age
      : "NA"
  );
  QuickSepsis.append(
    "maternal_fever",
    patient.getMotherProfile?.maternal_fever
      ? patient.getMotherProfile?.maternal_fever
      : "NA"
  );

  QuickSepsis.append(
    "baby_skin_colour",
    patient.getBabyQuickSepsis?.baby_skin_colour
      ? patient.getBabyQuickSepsis?.baby_skin_colour
      : "NA"
  );
  QuickSepsis.append(
    "baby_cry_sound",
    patient.getBabyQuickSepsis?.baby_cry_sound
      ? patient.getBabyQuickSepsis?.baby_cry_sound
      : "NA"
  );
  QuickSepsis.append(
    "baby_feeding_status",
    updateValue(
      patient.getBabyQuickSepsis?.baby_feeding_status
        ? patient.getBabyQuickSepsis?.baby_feeding_status
        : "NA"
    )
  );
  QuickSepsis.append(
    "breathing_rate",
    patient.getBabyQuickSepsis?.breathing_rate
      ? patient.getBabyQuickSepsis?.breathing_rate
      : "NA"
  );

  QuickSepsis.append(
    "oxygen_saturation",
    patient.getBabyQuickSepsis?.oxygen_saturation
      ? patient.getBabyQuickSepsis?.oxygen_saturation
      : "NA"
  );
  QuickSepsis.append(
    "heart_rate",
    patient.getBabyQuickSepsis?.heart_rate
      ? patient.getBabyQuickSepsis?.heart_rate
      : "NA"
  );
  QuickSepsis.append(
    "frequency_of_stools",
    patient.getBabyQuickSepsis?.frequency_of_stools
      ? patient.getBabyQuickSepsis?.frequency_of_stools
      : "NA"
  );

  return QuickSepsis;
};
