import React from "react";
import { connect } from "react-redux";
//import "../styles/footer.scss";
import CopyrightIcon from "@mui/icons-material/Copyright";

const Footer = (props) => {
  return (
    <React.Fragment>
      <div className="footer-copyright">
        <div>
          <p className="footer-content">
            Copyright © 2019-2024 Avyantra Health Technologies. All Rights
            Reserved
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
