import React, { useState, useEffect } from "react";
//import { useDispatch, useSelector } from "react-redux";
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";

import "../styles/DataEntry.scss";
import { padding } from "@mui/system";

const Gauge = (props) => {
  const min = 0;
  const max = 10;
  const [predScoreData, setPredScoreData] = useState("");
  React.useEffect(() => {
    setPredScoreData(props.data);
  }, [props.data]);
  const value = predScoreData.scoreData;

  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);

  const angle = angleScale(percent);

  const markerLocation = getCoordsOnArc(angle, 1 - (1 - 0.9) / 2);
  const greenArc = arc()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 2)
    .endAngle(0)
    .padAngle(0)
    .cornerRadius(2)();

  const orangeArc = arc()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(0.04)
    .endAngle(Math.PI / 3.33)
    .padAngle(0)
    .cornerRadius(2)();

  const redArc = arc()
    .innerRadius(1)
    .outerRadius(0.9)
    .startAngle(Math.PI / 3.2)
    .endAngle(Math.PI / 2)
    .padAngle(0)
    .cornerRadius(2)();

  const getBlobColor = (value) => {
    if (value >= 0 && value <= 5.0) return "#74D976";
    if (value >= 5.1 && value <= 8.0) return "#F9D372";
    if (value >= 8.1 && value <= 10) return "#F56D6D";
  };

  return (
    <div
      className="ScoreGenerationScoreData"
      style={{
        textAlign: "center",
        fontSize: "1.3em",
        lineHeight: "0em",
        fontWeight: "900",
        fontFeatureSettings: "'zero', 'tnum' 1",
        color: predScoreData.color,
      }}
    >
      <svg
        style={{ overflow: "visible" }}
        width="8em"
        viewBox={[-1, -1, 2, 1].join(" ")}
      >
        <path d={greenArc} fill="#74D976" />
        <path d={orangeArc} fill="#F9D372" />
        <path d={redArc} fill="#F56D6D" />
        <circle
          cx={markerLocation[0]}
          cy={markerLocation[1]}
          r="0.08"
          strokeWidth="0.04"
          fill="white"
          stroke={getBlobColor(value)}
        />
      </svg>
      <div
        style={{
          position: "relative",
          top: "-12px",
        }}
      >
        <span
          style={{
            position: "relative",
            top: "-20px",
            fontSize: "1.5em",
          }}
        >
          {value ? value: ""}
        </span>
        <p
          className="ScoreRisk"
          style={{ color: predScoreData.color, fontSize: "12px" }}
        >
          {predScoreData.risk}
        </p>
      </div>
    </div>
  );
};
const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
];
export default Gauge;
