import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import "../styles/DataEntry.scss";
const Numerics = /^[0-9 ]*$/;

import {
  babyGitAddition,
  updatePatientFormData,
  setBabyGITData,
  getBabyGITByReadingId,
  setFormTab,
  getPatientLastReadingData,
  getPatientBasicDetails,
  getBabyGITByReadingIdScore,
} from "../reducers/patientDataThunk";
import CircularProgress from "@mui/material/CircularProgress";

const BabyGit = (props) => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const hospitalId = user.userProfile.hospital_id;

  //const [frequencyOfStoolsChecked, setFrequencyOfStoolsChecked] = useState(false);

  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const readingId = patient.bmrndata?.reading_id;
  const reading = patient.Selectedreadingid;
  const loggedUserId = user.userProfile.user_id;
  const ptbmrn = patient.bmrndata?.baby_medical_record_number;
  const [dischargeFlag, setDischargeFlag] = useState("1");
  const [addBabyGITData, setAddBabyGITData] = useState(patient.babyGITData);

  let url = `patient/update/baby_git`;
  const [updateRecord, setUpdateRecord] = useState();
  const [errorFields, setErrorFields] = useState({});
  const [
    showFrequencyOfStoolsErrorMessage,
    setShowFrequencyOfStoolsErrorMessage,
  ] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isValid, setValid] = React.useState(true);
  useEffect(() => {
    verifyErrorFields();
  }, [errorFields]);

  const verifyErrorFields = async () => {
    let enableSubmitButton = true;
    const keys = Object.keys(errorFields);
    keys.map((key) => {
      errorFields[key] !== false ? (enableSubmitButton = false) : null;
    });
    setValid(enableSubmitButton);
  };
  useEffect(() => {
    const req = { baby_medical_record_number: ptbmrn,hospitalId };
    dispatch(getPatientBasicDetails(req))
      .unwrap()
      .then((resdata) => {
        const disFlag = resdata.response.discharge_flag;
        setDischargeFlag(disFlag);
      });
  }, [ptbmrn]);
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    setAddBabyGITData(patient.babyGITData);
  }, [patient.babyGITData]);

  useEffect(() => {
    if (patient?.bmrndata?.reading_id && patient.bmrndata?.study_id) {
      updateData(patient?.bmrndata?.reading_id, patient.bmrndata?.study_id);
    }
  }, [patient?.bmrndata?.reading_id, patient.bmrndata?.study_id]);

  const updateData = (readingId, studyid) => {
    const req = { readingId, studyid };
    dispatch(getBabyGITByReadingId(req))
      .unwrap()
      .then((resdata) => {
        if (resdata.response) {
          let GITData = {
            response: Object.assign({}, resdata.response),
          };
          const data =
            GITData.response && GITData.response ? GITData.response : undefined;
          const data1 =
            GITData.response && GITData.response.id
              ? setUpdateRecord(true)
              : setUpdateRecord(false);
          if (resdata.response) {
            setAddBabyGITData(
              GITData.response && GITData.response ? GITData.response : {}
            );
          }
        } else {
          const study_id = patient?.bmrndata?.study_id;
          dispatch(getPatientLastReadingData(study_id))
            .unwrap()
            .then((resdata) => {
              if (resdata.response) {
                let GITData = {
                  response: Object.assign({}, resdata.response.baby_git),
                };
                const data =
                  GITData.response && GITData.response
                    ? GITData.response
                    : undefined;
                const data1 =
                  GITData.response && GITData.response.id
                    ? setUpdateRecord(false)
                    : setUpdateRecord(false);
                if (resdata.response) {
                  setAddBabyGITData(
                    GITData.response && GITData.response ? GITData.response : {}
                  );
                }
              }
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;
    const fieldvalue = event.target.value;

    const newFormData = { ...addBabyGITData };
    newFormData[fieldname] = fieldvalue;

    setAddBabyGITData(newFormData);
    setBabyGITData(newFormData);
  };

  const HandleAddFormSubmit = async (event) => {
    event.preventDefault();
    await submitData(8);
  };

  useEffect(() => {
    if (props.triggerChildFunction) submitData();
  }, [props.triggerChildFunction]);

  const submitData = async (nextTab) => {
    const newFormData = { ...addBabyGITData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.id = "";
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;

    if (!updateRecord) {
      dispatch(babyGitAddition(newFormData))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyGITByReadingId(req));
          dispatch(getBabyGITByReadingIdScore(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        url,
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
          const req = { readingId, studyid };
          dispatch(getBabyGITByReadingId(req));
          dispatch(getBabyGITByReadingIdScore(req));
          props.updateSessionData(nextTab ? nextTab : props.nextTab);
          dispatch(setFormTab(nextTab ? nextTab : props.nextTab));
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const verifyFrequencyOfStools = (event) => {
    if (event.target.value >= 0 && event.target.value <= 12) {
      handleAddFormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: false,
        })
      );
      setShowFrequencyOfStoolsErrorMessage("");
    } else {
      setShowFrequencyOfStoolsErrorMessage(
        "Frequency Of Stools Value Should be Between 0 - 12"
      );
      setErrorFields(
        Object.assign({}, errorFields, {
          frequency_of_stools: true,
        })
      );
    }
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <div
          style={{
            height: "100px",
            paddingTop: "230px",
            textAlign: "center",
          }}
        >
          <CircularProgress color="success" />
        </div>
      ) : (
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="PatientRecordID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Record Id
                  </label>
                  <TextField
                    id="study_id"
                    name="study_id"
                    label=""
                    variant="outlined"
                    value={patient.bmrndata?.study_id}
                    onChange={handleAddFormChange}
                    className="dataentry_input"
                    size="small"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="AbdominalDistension"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Abdominal Distension
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyGITData?.abdominal_dystension}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag == "0"}
                  >
                    <ToggleButton
                      name="abdominal_dystension"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="abdominal_dystension"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="FrequencyOfStools"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Frequency of Stools
                    {/* <span style={{ paddingLeft: "2px", color: "red" }}>*</span> */}
                  </label>
                  <TextField
                    name="frequency_of_stools"
                    id="FrequencyOfStools"
                    className={
                      "dataentry_input " +
                      (errorFields.frequency_of_stools ? "errorField" : "")
                    }
                    variant="outlined"
                    size="small"
                    disabled={dischargeFlag == "0"}
                    InputLabelProps={{ shrink: true }}
                    value={addBabyGITData?.frequency_of_stools}
                    onChange={(e) => {
                      handleAddFormChange(e);
                      verifyFrequencyOfStools(e);
                    }}
                    error={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                    }
                    errorText={showFrequencyOfStoolsErrorMessage}
                    helperText={
                      errorFields.frequency_of_stools &&
                      showFrequencyOfStoolsErrorMessage
                        ? showFrequencyOfStoolsErrorMessage
                        : ""
                    }
                    onKeyDown={(event) => {
                      if (
                        !Numerics.test(event.key) &&
                        event.key != "Backspace"
                      ) {
                        event.preventDefault();
                        setShowFrequencyOfStoolsErrorMessage(
                          "Only Numerics are Acceptable"
                        );
                        setErrorFields(
                          Object.assign({}, errorFields, {
                            frequency_of_stools: true,
                          })
                        );
                      }
                    }}
                    
                          
                    InputProps={{
                      inputProps: {
                        maxLength: 2,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Diarrhea"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diarrhea
                  </label>

                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyGITData?.diarrhea}
                    exclusive
                    disabled={dischargeFlag == "0"}
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="diarrhea"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="diarrhea"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="diarrhea"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Vomiting"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Vomiting
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyGITData?.vomiting}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag == "0"}
                  >
                    <ToggleButton
                      name="vomiting"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="vomiting"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="vomiting"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="FeedingIntolerance"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Feeding Intolerance
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyGITData?.feeding_intolerance}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                    disabled={dischargeFlag == "0"}
                  >
                    <ToggleButton
                      name="feeding_intolerance"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="feeding_intolerance"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="feeding_intolerance"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                {isValid ? (
                  <Button
                    variant="outlined"
                    color="primary"
                    aria-label="add"
                    className="buttonStyle sign-up-button"
                    type="submit"
                    disabled={dischargeFlag == "0"}
                    style={{ cursor: "pointer" }}
                  >
                    Save {"&"} Next
                  </Button>
                ) : (
                  <Button
                    variant="outlined"
                    color="gray"
                    aria-label="add"
                    className="buttonStyle1 sign-up-button"
                    disabled
                  >
                    Save {"&"} Next
                  </Button>
                )}
              </div>
            </form>
          </CardContent>
        </Card>
      )}
    </React.Fragment>
  );
};

export default BabyGit;
