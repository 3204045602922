import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
//import File from "../../common/assets/images/File.svg";
import ReportFile from "../../common/assets/images/fileNew.svg";
import Share from "../../common/assets/images/Share.svg";
import Download from "../../common/assets/images/Download.svg";
import Delete from "../../common/assets/images/Delete.svg";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import { Box } from "@mui/system";
import NotificationView from "../../Dashboard/components/NotificationView";
import PageviewIcon from "@mui/icons-material/Pageview";
import ImageSearchIcon from "@mui/icons-material/ImageSearch";
import imageCompression from "browser-image-compression";
import {
  Grid,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Card,
  CardContent,
  Alert,
  AlertTitle,
} from "@mui/material";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";

import "../styles/DataEntry.scss";
import { multiPartFileUpload } from "../reducers/patientDataThunk";

import {
  getFilesDetailsFromTable,
  downloadReport,
  deleteReport,
} from "../reducers/patientDataThunk";

import { save } from "save-file";
import { ToastContainer, toast } from "react-toastify";
import ImageView from "../../Dashboard/components/ImageView";

const Reports = (props) => {
  const location = useLocation();
  const patient = useSelector((state) => state.patient);

  const [deleteSeleFile, setDelete] = React.useState(false);

  const [showSelectedFile, setSelectedFile] = useState([]);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [pdfData, setPdfData] = React.useState(null);
  const [fileType, setFileType] = React.useState(null);

  const handleClose = () => {
    setOpen(false);
  };
  const study_id =
    props && props.selectedPatient
      ? props.selectedPatient.study_id
      : patient.bmrndata?.study_id;
  //const reading = patient.globalRecord?.reading_id;
  const reading =
    props && props.selectedPatient
      ? props.selectedPatient.reading
      : patient.bmrndata?.reading_id;
  const baby_medical_record_number =
    props && props.selectedPatient
      ? props.selectedPatient.baby_medical_record_number
      : patient.bmrndata?.baby_medical_record_number;

  const userdata = sessionStorage.getItem("user");
  const allowedPermissions = userdata ? JSON.parse(userdata).permissions : {};

  const [selectedFiles, setSelectedFiles] = useState("");
  const [listCaseSheets, setListCaseSheets] = useState([]);
  const [listDiagReports, setlistDiagReports] = useState([]);

  const [listImagings, setListImagings] = useState([]);
  const [otherData, setOtherData] = useState([]);

  const dispatch = useDispatch();
    const [seconds, setSeconds] = useState(0)

  /* useEffect(() => {
    getReports(baby_medical_record_number, reading);
  }, [
    patient.bmrndata?.reading_id,
    patient.bmrndata?.baby_medical_record_number,
  ]); */

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
          clearInterval(interval);
        } else {
          setSeconds(seconds - 1);
        }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  useEffect(() => {
    if (
      reading &&
      baby_medical_record_number
      //patient.bmrndata?.baby_medical_record_number
    ) {
      getReports(
        //patient.bmrndata?.baby_medical_record_number,
        baby_medical_record_number,
        reading
      );
    }
  }, [
    reading,
    baby_medical_record_number,
  ]);

  const getReports = (baby_medical_record_number, reading) => {
    const req = { baby_medical_record_number, reading };
    dispatch(getFilesDetailsFromTable(req))
      .unwrap()
      .then((resdata) => {
        const caseSheets = resdata.response[0].filter(
          (d) => d.document_type === "CaseSheets"
        );
        setListCaseSheets(caseSheets);

        const diagReports = resdata.response[0].filter(
          (d) => d.document_type === "DiagReports"
        );
        setlistDiagReports(diagReports);

        const imagings = resdata.response[0].filter(
          (d) => d.document_type === "Imagings"
        );
        setListImagings(imagings);

        const otherReport = resdata.response[0].filter(
          (d) => d.document_type === "Others"
        );
        setOtherData(otherReport);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadRefDiag = async (event) => {
    setSelectedFiles(event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    let currentFile = event.target.files[0];
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

      //let compressedFile = await imageCompression(currentFile, options);
      const compressedBlob = await imageCompression(currentFile, options);
      const compressedFile = new File([compressedBlob], currentFile.name, {
        type: compressedBlob.type,
      });
    const formData_diag = new FormData();
    const Puid_diag = baby_medical_record_number + reading;
    const document_type_diag = "DiagReports";
    const user_id = "NA";
    formData_diag.append("study_id", study_id);
    formData_diag.append("user_id", user_id);
    formData_diag.append("document_type", document_type_diag);
    formData_diag.append("Puid", Puid_diag);
    formData_diag.append("bmrn", baby_medical_record_number);
    formData_diag.append("file", compressedFile);
    dispatch(multiPartFileUpload(formData_diag))
      .unwrap()
      .then((resdata) => {
        toast.success("File Uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getReports(
          //patient.bmrndata?.baby_medical_record_number,
          baby_medical_record_number,
          reading
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadRefImages = async (event) => {
    setSelectedFiles(event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    let currentFile = event.target.files[0];
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

      //let compressedFile = await imageCompression(currentFile, options);
      const compressedBlob = await imageCompression(currentFile, options);
      const compressedFile = new File([compressedBlob], currentFile.name, {
        type: compressedBlob.type,
      });
    const formData_img = new FormData();
    const Puid = baby_medical_record_number + reading;
    const document_type = "Imagings";
    const user_id = "NA";
    formData_img.append("study_id", study_id);
    formData_img.append("user_id", user_id);
    formData_img.append("document_type", document_type);
    formData_img.append("Puid", Puid);
    formData_img.append("bmrn", baby_medical_record_number);
    formData_img.append("file", compressedFile);
    dispatch(multiPartFileUpload(formData_img))
      .unwrap()
      .then((resdata) => {
        toast.success("File Uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getReports(
          baby_medical_record_number,
          reading
        );
        /* getReports(
          patient.bmrndata?.baby_medical_record_number,
          patient.bmrndata?.reading_id
        ); */
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const uploadOtherImages = async (event) => {
    setSelectedFiles(event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    let currentFile = event.target.files[0];
    const options = {
      maxSizeMB: 0.3,
      maxWidthOrHeight: 800,
      useWebWorker: true,
    };

      //let compressedFile = await imageCompression(currentFile, options);
      const compressedBlob = await imageCompression(currentFile, options);
      const compressedFile = new File([compressedBlob], currentFile.name, {
        type: compressedBlob.type,
      });
    const formData_img = new FormData();
    const Puid = baby_medical_record_number + reading;
    const document_type = "Others";
    const user_id = "NA";
    formData_img.append("study_id", study_id);
    formData_img.append("user_id", user_id);
    formData_img.append("document_type", document_type);
    formData_img.append("Puid", Puid);
    formData_img.append("bmrn", baby_medical_record_number);
    formData_img.append("file", compressedFile);
    dispatch(multiPartFileUpload(formData_img))
      .unwrap()
      .then((resdata) => {
        toast.success("File Uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getReports(
          baby_medical_record_number,
          reading
        );
        /* getReports(
          patient.bmrndata?.baby_medical_record_number,
          patient.bmrndata?.reading_id
        ); */
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadRefCaseSheets = async (event) => {
    setSelectedFiles(event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    let currentFile = event.target.files[0];
    const options = {
          maxSizeMB: 0.3,
          maxWidthOrHeight: 800,
          useWebWorker: true,
        };
    
          //let compressedFile = await imageCompression(currentFile, options);
          const compressedBlob = await imageCompression(currentFile, options);
          const compressedFile = new File([compressedBlob], currentFile.name, {
            type: compressedBlob.type,
          });
    const formData = new FormData();
    const Puid = baby_medical_record_number + reading;
    const document_type = "CaseSheets";
    const user_id = "NA";
    formData.append("study_id", study_id);
    formData.append("user_id", user_id);
    formData.append("document_type", document_type);
    formData.append("Puid", Puid);
    formData.append("bmrn", baby_medical_record_number);
    formData.append("file", compressedFile);
    dispatch(multiPartFileUpload(formData))
      .unwrap()
      .then((resdata) => {
        toast.success("File Uploaded successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        getReports(
          //patient.bmrndata?.baby_medical_record_number,
          baby_medical_record_number,
          reading
        );
        /* getReports(
          patient.bmrndata?.baby_medical_record_number,
          patient.bmrndata?.reading_id
        ); */
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const downloadRep = (e, fileName) => {
    
    setSeconds(3);
    e.preventDefault();

    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        setPdfData(resdata.Body.data);
        await save(resdata.Body, fileName);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const Deletefile = (e, fileName) => {
    setSelectedFile(fileName);
    setDelete(true);
  };

  const deleteRep = (e, showSelectedFile) => {
    e.preventDefault();

    dispatch(deleteReport({ filename: showSelectedFile }))
      .unwrap()
      .then((resdata) => {
        toast.success("File deleted successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setDelete(false);
        getReports(
          //patient.bmrndata?.baby_medical_record_number,
          baby_medical_record_number,
          reading
        );
        /* getReports(
          patient.bmrndata?.baby_medical_record_number,
          patient.bmrndata?.reading_id
        ); */
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const viewFile = async (e, fileName) => {
    e.preventDefault();
    setSelectedFile(fileName);
    dispatch(downloadReport(fileName))
      .unwrap()
      .then(async (resdata) => {
        setDialogOpen(true);
        setPdfData(resdata.Body.data);
        if (fileName.endsWith(".pdf")) {
          setFileType("pdf");
        } else if (fileName.endsWith(".jpg") || fileName.endsWith(".jpeg")) {
          setFileType("jpg");
        } else if (fileName.endsWith(".png")) {
          setFileType("png");
        } else {
          // console.log("Unknown file type");
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <>
      <React.Fragment>
        {/* Same as */}
        <Dialog
          fullWidth={true}
          maxWidth="lg"
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          style={{ width: "auto" }}
          className="report-dialog"
        >
          <DialogTitle style={{ borderBottom: "0.8px solid #f0f1fc" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>{showSelectedFile}</div>
              <div>
                <Link
                  className="FIle-right-extreme"
                  onClick={() => setDialogOpen(false)}
                >
                  <img
                    src={Close}
                    style={{ paddingLeft: 418 }}
                    className="file-img-right-extreme"
                    alt=""
                  />
                </Link>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {fileType && fileType === "pdf" ? (
              <NotificationView data={pdfData} action={setDialogOpen} />
            ) : fileType === "jpg" || "png" ? (
              <ImageView data={pdfData} action={setDialogOpen} />
            ) : null}
          </DialogContent>
        </Dialog>
        <ToastContainer />
        <form id="bmrn-form">
          <div>
            <form id="reports-generation">
              <div className="round-bordered">
                <div
                  className="report-header"
                  style={{ borderRadius: "0.5rem 0.5rem 0 0" }}
                >
                  <Grid container className="CaseSheets">
                    <Grid item xs={12} sm={6} className="file-name">
                      <span style={{ marginLeft: "0.4rem" }}>Case Sheets</span>
                    </Grid>
                    {allowedPermissions &&
                      allowedPermissions.bmrn && (
                        <Grid item xs={12} sm={6} className="uploadClass">
                          <label htmlFor="btn-upload-referral">
                            <input
                              id="btn-upload-referral"
                              name="btn-upload-referral"
                              style={{ display: "none" }}
                              type="file"
                              accept="application/pdf, image/*"
                              onChange={uploadRefCaseSheets}
                            />
                            <Button
                              className="upload-reports"
                              color="primary"
                              variant="contained"
                              component="span"
                              size="small"
                            >
                              Upload
                            </Button>
                          </label>
                        </Grid>
                      )}
                  </Grid>
                </div>
                <div className="report-content">
                  <Grid container>
                    <Grid item xs={12} sm={12} className="No_reports_Found">
                      <div>
                        <List>
                          {listCaseSheets.map((sheet) => (
                            <ListItem>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-icon">
                                      <Link to="/" className="FIle-ref">
                                        {" "}
                                        <img
                                          src={ReportFile}
                                          className="file-img"
                                        />
                                      </Link>
                                    </ListItemIcon>
                                    <div
                                      className={
                                        location?.pathname === "/Dashboard"
                                          ? "dashboard-file-name"
                                          : "file-name"
                                      }
                                      title={sheet.s3_filename}
                                    >
                                      {sheet.s3_filename}
                                    </div>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        onClick={(e) =>
                                          viewFile(e, sheet.s3_filename)
                                        }
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.10)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <ImageSearchIcon
                                          className="DownLoad-img"
                                          color="primary"
                                          style={{
                                            width: "0.8em",
                                            height: "0.8em",
                                          }}
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        aria-label="download"
                                        onClick={(e) => seconds > 0 ? "" : downloadRep(e, sheet.s3_filename)}
                                        disabled={seconds > 0}
                                        /* onClick={(e) =>
                                          downloadRep(e, sheet.s3_filename)
                                        } */
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.10)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                          color: seconds > 0 ? "#gray" : ""
                                        }}
                                      >
                                        <img
                                          src={Download}
                                          className="DownLoad-img"
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    {allowedPermissions &&
                                      allowedPermissions.bmrn && (
                                        <ListItemIcon className="file-align file-action-icons">
                                          <IconButton
                                            className="acrossBtn"
                                            aria-label="delete"
                                            onClick={(e) =>
                                              Deletefile(e, sheet.s3_filename)
                                            }
                                            style={{
                                              backgroundColor:
                                                "rgba(244, 131, 81, 0.15)",
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <img src={Delete} />
                                          </IconButton>
                                        </ListItemIcon>
                                      )}
                                  </div>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="report-header">
                  <Grid container className="CaseSheets">
                    <Grid item xs={12} sm={6} className="file-name">
                      Diagnostic Reports
                    </Grid>
                    {allowedPermissions &&
                      allowedPermissions.bmrn && (
                        <Grid item xs={12} sm={6} className="uploadClass">
                          <label htmlFor="btn-upload-referral-diag">
                            <input
                              id="btn-upload-referral-diag"
                              name="btn-upload-diag"
                              style={{ display: "none" }}
                              type="file"
                              accept="application/pdf, image/*"
                              onChange={uploadRefDiag}
                            />
                            <Button
                              className="upload-reports"
                              color="primary"
                              variant="contained"
                              component="span"
                              size="small"
                            >
                              Upload
                            </Button>
                          </label>
                        </Grid>
                      )}
                  </Grid>
                </div>
                <div className="report-content">
                  <Grid container>
                    <Grid item xs={12} sm={12} className="No_reports_Found">
                      <div>
                        <List>
                          {listDiagReports.map((sheet) => (
                            <ListItem>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-icon">
                                      <Link to="/" className="FIle-ref">
                                        <img
                                          src={ReportFile}
                                          className="file-img"
                                        />
                                      </Link>
                                    </ListItemIcon>
                                    <div
                                      className={
                                        location?.pathname === "/Dashboard"
                                          ? "dashboard-file-name"
                                          : "file-name"
                                      }
                                      title={sheet.s3_filename}
                                    >
                                      {sheet.s3_filename}
                                    </div>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        onClick={(e) =>
                                          viewFile(e, sheet.s3_filename)
                                        }
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.10)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <ImageSearchIcon
                                          className="DownLoad-img"
                                          color="primary"
                                          style={{
                                            width: "0.8em",
                                            height: "0.8em",
                                          }}
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        aria-label="download"
                                        onClick={(e) =>
                                          downloadRep(e, sheet.s3_filename)
                                        }
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.2)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <img
                                          src={Download}
                                          className="DownLoad-img"
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    {allowedPermissions &&
                                      allowedPermissions.bmrn && (
                                        <ListItemIcon className="file-align file-action-icons">
                                          <IconButton
                                            className="acrossBtn"
                                            aria-label="delete"
                                            onClick={(e) =>
                                              Deletefile(e, sheet.s3_filename)
                                            }
                                            style={{
                                              backgroundColor:
                                                "rgba(244, 131, 81, 0.15)",
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <img src={Delete} />
                                          </IconButton>
                                        </ListItemIcon>
                                      )}
                                  </div>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="report-header">
                  <Grid container className="CaseSheets">
                    <Grid item xs={12} sm={6} className="file-name">
                      Imagings
                      <span style={{ fontSize: "13px" }}>
                        (X-Rays, CT Scans)
                      </span>
                    </Grid>
                    {allowedPermissions &&
                      allowedPermissions.bmrn && (
                        <Grid item xs={12} sm={6} className="uploadClass">
                          <label htmlFor="btn-upload-referral-img">
                            <input
                              id="btn-upload-referral-img"
                              name="btn-upload-imag"
                              style={{ display: "none" }}
                              type="file"
                              accept="application/pdf, image/*"
                              onChange={uploadRefImages}
                            />
                            <Button
                              className="upload-reports"
                              color="primary"
                              variant="contained"
                              component="span"
                              size="small"
                            >
                              Upload
                            </Button>
                          </label>
                        </Grid>
                      )}
                  </Grid>
                </div>
                <div className="report-content">
                  <Grid container>
                    <Grid item xs={12} sm={12} className="No_reports_Found">
                      <div>
                        <List>
                          {listImagings.map((sheet) => (
                            <ListItem>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-icon">
                                      <Link to="/" className="FIle-ref">
                                        {" "}
                                        <img
                                          src={ReportFile}
                                          className="file-img"
                                        />
                                      </Link>
                                    </ListItemIcon>
                                    <div
                                      className={
                                        location?.pathname === "/Dashboard"
                                          ? "dashboard-file-name"
                                          : "file-name"
                                      }
                                      title={sheet.s3_filename}
                                    >
                                      {sheet.s3_filename}
                                    </div>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        onClick={(e) =>
                                          viewFile(e, sheet.s3_filename)
                                        }
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.2)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <ImageSearchIcon
                                          className="DownLoad-img"
                                          color="primary"
                                          style={{
                                            width: "0.8em",
                                            height: "0.8em",
                                          }}
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        aria-label="download"
                                        onClick={(e) =>
                                          downloadRep(e, sheet.s3_filename)
                                        }
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.2)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <img
                                          src={Download}
                                          className="DownLoad-img"
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    {allowedPermissions &&
                                      allowedPermissions.bmrn && (
                                        <ListItemIcon className="file-align file-action-icons">
                                          <IconButton
                                            className="acrossBtn"
                                            aria-label="delete"
                                            onClick={(e) =>
                                              Deletefile(e, sheet.s3_filename)
                                            }
                                            style={{
                                              backgroundColor:
                                                "rgba(244, 131, 81, 0.15)",
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <img src={Delete} />
                                          </IconButton>
                                        </ListItemIcon>
                                      )}
                                  </div>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                  {deleteSeleFile && (
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle style={{ borderBottom: "1px solid #000" }}>
                        <Link
                          to="/"
                          className="FIle-left"
                          onClick={handleClose}
                          style={{ marginRight: "20px" }}
                        >
                          <img
                            src={Back}
                            className="file-img-left"
                            alt=""
                            style={{ width: "12px" }}
                          />
                        </Link>
                        <span>Delete Conformation</span>
                        <Link
                          to="/"
                          className="FIle-right-extreme"
                          onClick={handleClose}
                        >
                          <img
                            src={Close}
                            className="file-img-right-extreme"
                            alt=""
                          />
                        </Link>
                      </DialogTitle>
                      <DialogContent>
                        <Box>
                          <form id="bmrn-form">
                            <Card style={{ marginTop: 10 }} variant="none">
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12} sm={12}>
                                    <Alert severity="error">
                                      <AlertTitle>
                                        Are you sure you want to delete this
                                        File
                                      </AlertTitle>
                                    </Alert>
                                  </Grid>
                                  <div className="formSection">
                                    <Grid container>
                                      <Grid item xs={12} sm={2}></Grid>
                                      <Grid item xs={12} sm={4}>
                                        <Button
                                          variant="contained"
                                          className="back"
                                          onClick={handleClose}
                                        >
                                          Back
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <Button
                                          variant="contained"
                                          className="purple-add"
                                          type="submit"
                                          onClick={(e) =>
                                            deleteRep(e, showSelectedFile)
                                          }
                                        >
                                          <span>Delete</span>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </CardContent>
                            </Card>
                          </form>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  )}
                </div>
                <div className="report-header">
                  <Grid container className="CaseSheets">
                    <Grid item xs={12} sm={6} className="file-name">
                      Others
                    </Grid>
                    {allowedPermissions &&
                      allowedPermissions.bmrn && (
                        <Grid item xs={12} sm={6} className="uploadClass">
                          <label htmlFor="btn-upload-other-img">
                            <input
                              id="btn-upload-other-img"
                              name="btn-upload-imag"
                              style={{ display: "none" }}
                              type="file"
                              accept="application/pdf, image/*"
                              onChange={uploadOtherImages}
                            />
                            <Button
                              className="upload-reports"
                              color="primary"
                              variant="contained"
                              component="span"
                              size="small"
                            >
                              Upload
                            </Button>
                          </label>
                        </Grid>
                      )}
                  </Grid>
                </div>
                <div className="report-content">
                  <Grid container>
                    <Grid item xs={12} sm={12} className="No_reports_Found">
                      <div>
                        <List>
                          {otherData.map((sheet) => (
                            <ListItem>
                              <Grid container>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-icon">
                                      <Link to="/" className="FIle-ref">
                                        {" "}
                                        <img
                                          src={ReportFile}
                                          className="file-img"
                                        />
                                      </Link>
                                    </ListItemIcon>
                                    <div
                                      className={
                                        location?.pathname === "/Dashboard"
                                          ? "dashboard-file-name"
                                          : "file-name"
                                      }
                                      title={sheet.s3_filename}
                                    >
                                      {sheet.s3_filename}
                                    </div>
                                  </div>
                                  <div style={{ display: "flex" }}>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        onClick={(e) =>
                                          viewFile(e, sheet.s3_filename)
                                        }
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.2)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <ImageSearchIcon
                                          className="DownLoad-img"
                                          color="primary"
                                          style={{
                                            width: "0.8em",
                                            height: "0.8em",
                                          }}
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    <ListItemIcon className="file-align file-action-icons">
                                      <IconButton
                                        className="acrossBtn"
                                        aria-label="download"
                                        onClick={(e) =>
                                          downloadRep(e, sheet.s3_filename)
                                        }
                                        style={{
                                          backgroundColor:
                                            "rgba(101, 114, 228, 0.2)",
                                          cursor: "pointer",
                                          marginLeft: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        <img
                                          src={Download}
                                          className="DownLoad-img"
                                        />
                                      </IconButton>
                                    </ListItemIcon>
                                    {allowedPermissions &&
                                      allowedPermissions.bmrn && (
                                        <ListItemIcon className="file-align file-action-icons">
                                          <IconButton
                                            className="acrossBtn"
                                            aria-label="delete"
                                            onClick={(e) =>
                                              Deletefile(e, sheet.s3_filename)
                                            }
                                            style={{
                                              backgroundColor:
                                                "rgba(244, 131, 81, 0.15)",
                                              cursor: "pointer",
                                              marginLeft: "10px",
                                              borderRadius: "5px",
                                            }}
                                          >
                                            <img src={Delete} />
                                          </IconButton>
                                        </ListItemIcon>
                                      )}
                                  </div>
                                </Grid>
                              </Grid>
                            </ListItem>
                          ))}
                        </List>
                      </div>
                    </Grid>
                  </Grid>
                  {deleteSeleFile && (
                    <Dialog open={open} onClose={handleClose}>
                      <DialogTitle style={{ borderBottom: "1px solid #000" }}>
                        <Link
                          to="/"
                          className="FIle-left"
                          onClick={handleClose}
                          style={{ marginRight: "20px" }}
                        >
                          <img
                            src={Back}
                            className="file-img-left"
                            alt=""
                            style={{ width: "12px" }}
                          />
                        </Link>
                        <span>Delete Conformation</span>
                        <Link
                          to="/"
                          className="FIle-right-extreme"
                          onClick={handleClose}
                        >
                          <img
                            src={Close}
                            className="file-img-right-extreme"
                            alt=""
                          />
                        </Link>
                      </DialogTitle>
                      <DialogContent>
                        <Box>
                          <form id="bmrn-form">
                            <Card style={{ marginTop: 10 }} variant="none">
                              <CardContent>
                                <Grid container>
                                  <Grid item xs={12} sm={12}>
                                    <Alert severity="error">
                                      <AlertTitle>
                                        Are you sure you want to delete this
                                        File
                                      </AlertTitle>
                                    </Alert>
                                  </Grid>
                                  <div className="formSection">
                                    <Grid container>
                                      <Grid item xs={12} sm={2}></Grid>
                                      <Grid item xs={12} sm={4}>
                                        <Button
                                          variant="contained"
                                          className="back"
                                          onClick={handleClose}
                                        >
                                          Back
                                        </Button>
                                      </Grid>
                                      <Grid item xs={12} sm={4}>
                                        <Button
                                          variant="contained"
                                          className="purple-add"
                                          type="submit"
                                          onClick={(e) =>
                                            deleteRep(e, showSelectedFile)
                                          }
                                        >
                                          <span>Delete</span>
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </div>
                                </Grid>
                              </CardContent>
                            </Card>
                          </form>
                        </Box>
                      </DialogContent>
                    </Dialog>
                  )}
                </div>
              </div>
            </form>
          </div>
        </form>
      </React.Fragment>
    </>
  );
};

export default Reports;
