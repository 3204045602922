import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Grid,
  ToggleButton,
  ToggleButtonGroup,
  Card,
  CardContent,
  MenuItem,
  Select,
  Button,
  Snackbar,
  FormGroup,
} from "@mui/material";
import "../styles/DataEntry.scss";
import {
  URINE_OUTPUT,
  CAPILLARY_REFILL,
  CENTRAL_LINE_VALUE,
} from "../utils/mockData";
import {
  finalAddition,
  updatePatientFormData,
  setBabyFinalDiagData,
  getBabyFinalDiagByReadingId,
} from "../reducers/patientDataThunk";
import moment from "moment";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

const FinalDiagnosis = () => {
  const patient = useSelector((state) => state.patient);
  const user = useSelector((state) => state.user);
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();

  const study_id = patient.bmrndata?.study_id;
  const studyid = patient.bmrndata?.study_id;
  const readingId = patient?.Selectedreadingid;
  const reading = patient.Selectedreadingid;
  const loggedUserId = user.userProfile.user_id;
  const baby_date_of_admission = patient.getBabyProfile?.baby_date_of_admission;
  const [dateOfDischarge, setDateOfDischarge] = React.useState(
    moment().format()
  );
  const [addBabyFinalData, setAddBabyFinalData] = useState(
    patient.babyFinalDiagData
  );
  //const [diagOtherChecked, setDiagOtherChecked] = useState(false);
  const [daysOfStay, setDaysOfStay] = useState();

  useEffect(() => {
    const start = moment(baby_date_of_admission).format();
    const end = moment(dateOfDischarge).format();
    const diff = moment(end).diff(start, "days");
    setDaysOfStay(diff);
  }, [baby_date_of_admission, dateOfDischarge]);

  let url = `patient/update/baby_final`;
  const [updateRecord, setUpdateRecord] = useState();

  useEffect(() => {
    setAddBabyFinalData(patient.babyFinalDiagData);
  }, [patient.babyFinalDiagData]);

  useEffect(() => {
    if (patient.Selectedreadingid) {
      updateData();
    }
  }, [patient.Selectedreadingid]);

  const updateData = (event) => {
    const req = { readingId, studyid };
    dispatch(getBabyFinalDiagByReadingId(req))
      .unwrap()
      .then((resdata) => {
        let INVData = {
          response: Object.assign({}, resdata.response),
        };
        const data =
          INVData.response && INVData.response ? INVData.response : undefined;
        const data1 =
          INVData.response && INVData.response.id
            ? setUpdateRecord(true)
            : setUpdateRecord(false);
        if (resdata.response) {
          setAddBabyFinalData(
            INVData.response && INVData.response ? INVData.response : {}
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleAddFormChange = (event) => {
    const fieldname = event.target.name;

    const fieldvalue = event.target.value;

    const newFormData = { ...addBabyFinalData };
    newFormData[fieldname] = fieldvalue;

    setAddBabyFinalData(newFormData);
    setBabyFinalDiagData(newFormData);
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...addBabyFinalData };
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    newFormData.study_id = patient.bmrndata?.study_id;
    newFormData.loggedUserId = loggedUserId;
    newFormData.reading =
      JSON.stringify(readingId) != "{}"
        ? readingId
        : patient?.bmrndata?.reading_id;

    if (!updateRecord) {
      dispatch(finalAddition(newFormData))
        .unwrap()
        .then((resdata) => {
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      newFormData.reading = patient.Selectedreadingid;
      const req = {
        url,
        study_id,
        reading:
          JSON.stringify(readingId) != "{}"
            ? readingId
            : patient?.bmrndata?.reading_id,
        data: newFormData,
        loggedUserId,
      };
      dispatch(updatePatientFormData(req))
        .unwrap()
        .then((resdata) => {
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Card variant="none">
          <CardContent>
            <form onSubmit={HandleAddFormSubmit} id="mother-medical-form">
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="PatientRecordID"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Record Id
                  </label>
                  <TextField
                    id="study_id"
                    name="study_id"
                    label=""
                    variant="outlined"
                    value={patient.bmrndata?.study_id}
                    onChange={handleAddFormChange}
                    className="dataentry_input"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiachargeDate"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Discharge Date
                  </label>
                  <DatePicker
                    id="baby_discharge_date"
                    name="baby_discharge_date"
                    size="small"
                    className="dataentry_input"
                    maxDate={moment().format()}
                    minDate={baby_date_of_admission}
                    value={addBabyFinalData?.baby_discharge_date}
                    onChange={(date) => {
                      setDateOfDischarge(date["$d"]);
                      handleAddFormChange({
                        target: {
                          name: "baby_discharge_date",
                          value: date["$d"],
                        },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        className="dataentry_input"
                        helperText={null}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DaysOfStayInHospital"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Days of Stay in Hospital
                  </label>
                  <FormGroup row>
                    <TextField
                      id="days_of_stay_in_hospital"
                      label=""
                      name="days_of_stay_in_hospital"
                      variant="outlined"
                      size="small"
                      className="dataentry_input"
                      value={daysOfStay}
                      onChange={handleAddFormChange}
                      disabled
                    />
                  </FormGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DischargeStatus"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Discharge Status
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.discharge_status}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="discharge_status"
                      value="DISCHARGED"
                      size="small"
                      className="dataentry_input"
                    >
                      Discharged
                    </ToggleButton>
                    <ToggleButton
                      name="discharge_status"
                      value="EXPIRED"
                      size="small"
                      className="dataentry_input"
                    >
                      Expired
                    </ToggleButton>
                    <ToggleButton
                      name="discharge_status"
                      value="LAMA"
                      size="small"
                      className="dataentry_input"
                    >
                      LAMA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagnosisSepsis"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Sepsis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_sepsis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_sepsis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_sepsis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_sepsis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="Diag_EOS_LOS_NA"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis (EOS/LOS/NA)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_eos_los}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_eos_los"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_eos_los"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_eos_los"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagnosisMeningitis"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Meningitis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_meningitis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_meningitis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_meningitis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_meningitis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagnosisSeizures"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Seizures)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_seizures}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_seizures"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_seizures"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_seizures"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagnosisRDS"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (RDS)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_rds}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_rds"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_rds"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_rds"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagTTNB"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (TTNB)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_ttnb}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_ttnb"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_ttnb"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_ttnb"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagJaundice"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Jaundice)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_jaundice}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_jaundice"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_jaundice"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_jaundice"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagLBW"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (LBW)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_lbw}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_lbw"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_lbw"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_lbw"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagLGA_SGA_AGA"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (LGA/SGA/AGA)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_lga_sga_aga_suspect}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_lga_sga_aga_suspect"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_lga_sga_aga_suspect"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_lga_sga_aga_suspect"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagAnemia"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Anemia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_anemia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_anemia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_anemia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_sepsis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagCongenitalHeartDisease"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Congenital Heart Disease)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_dextochordia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_dextochordia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_dextochordia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_dextochordia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor=" DiagHypoglycemia"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis (Hypoglycemia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_hypoglycemia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_hypoglycemia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_hypoglycemia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_hypoglycemia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagHypocalcemia"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Hypocalcemia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_hypocalcemia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_hypocalcemia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_hypocalcemia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_hypocalcemia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagGastroenteritis"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Gastroenteritis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_gastroenteritis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_gastroenteritis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_gastroenteritis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_gastroenteritis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor=" PerinatalRespDepression"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis (Perinatal Respiratory Depression)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={
                      addBabyFinalData?.final_diagnosis_perinatal_respiratory_depression
                    }
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_perinatal_respiratory_depression"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_perinatal_respiratory_depression"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_perinatal_respiratory_depression"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagShock"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Shock)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_shock}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_shock"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_shock"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_shock"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagFedingIntolerence"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Feeding Intolerence)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={
                      addBabyFinalData?.final_diagnosis_feeding_intolerence
                    }
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_feeding_intolerence"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_feeding_intolerence"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_feeding_intolerence"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagSepticArthritis"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis(Septic Arthritis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_septic_arthritis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_septic_arthritis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_septic_arthritis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_septic_arthritis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagEndoCarditis"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(Endocarditis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_endocarditis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_endocarditis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_endocarditis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_endocarditis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagPeritonitis"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(Peritonitis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_peritonitis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_peritonitis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_peritonitis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_peritonitis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagSoftTissueAbscess"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis(Soft Tissue Abscess)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={
                      addBabyFinalData?.final_diagnosis_soft_tissue_abscess
                    }
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_soft_tissue_abscess"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_soft_tissue_abscess"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_soft_tissue_abscess"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagCoagulopathy"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(Coagulopathy)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_coagulopathy}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_coagulopathy"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_coagulopathy"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_coagulopathy"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagUTI"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(UTI)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_uti}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_uti"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_uti"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_uti"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagUmbilicalSepsis"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis(Umblical Sepsis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_umblical_sepsis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_umblical_sepsis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_umblical_sepsis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_umblical_sepsis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagBleedingManifestation"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(Bleeding Manifestation)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={
                      addBabyFinalData?.final_diagnosis_bleeding_manifestation
                    }
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_bleeding_manifestation"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_bleeding_manifestation"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_bleeding_manifestation"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagAsphyxia"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(Asphyxia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_asphyxia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_asphyxia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_asphyxia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_asphyxia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor=" DiagPneumonia"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis(Pneumonia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_pneumonia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_pneumonia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_pneumonia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_pneumonia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagCentralPeripheralTempDiff"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(Central Peripheral temperature difference)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_central_peripheral}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_central_peripheral"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_central_peripheral"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_central_peripheral"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagHypoxia"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis (Hypoxia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_hypoxia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_hypoxia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_hypoxia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_hypoxia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagMetabolicAcidosis"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis (Metabolic Acidosis)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_metabolic_acidosis}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_metabolic_acidosis"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_metabolic_acidosis"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_metabolic_acidosis"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagPulmonaryHemorrhage"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(Pulmonary Hemorrhage)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={
                      addBabyFinalData?.final_diagnosis_pulmonary_hemerrage
                    }
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_pulmonary_hemerrage"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_pulmonary_hemerrage"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_pulmonary_hemerrage"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagThrombocytopenia"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    Diagnosis(thrombocytopenia)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_thrombocytopenia}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_thrombocytopenia"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_thrombocytopenia"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_thrombocytopenia"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagSkinPustules"
                    className="dataentry_label"
                    style={{ display: "block" }}
                  >
                    Diagnosis (Skin Pustules)
                  </label>
                  <ToggleButtonGroup
                    color="primary"
                    value={addBabyFinalData?.final_diagnosis_skin_pustules}
                    exclusive
                    onChange={handleAddFormChange}
                    className="Toggle_input"
                  >
                    <ToggleButton
                      name="final_diagnosis_skin_pustules"
                      value="YES"
                      size="small"
                      className="dataentry_input"
                    >
                      YES
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_skin_pustules"
                      value="NO"
                      size="small"
                      className="dataentry_input"
                    >
                      NO
                    </ToggleButton>
                    <ToggleButton
                      name="final_diagnosis_skin_pustules"
                      value="NA"
                      size="small"
                      className="dataentry_input"
                    >
                      NA
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <label
                    htmlFor="DiagOther"
                    style={{ display: "block" }}
                    className="dataentry_label"
                  >
                    {/* <input
                name="DiagOtherCheckBox"
                type="checkbox"
                checked={diagOtherChecked}
                onChange={() => {
                    setDiagOtherChecked(true);
                }}
              /> */}
                    Diagnosis (Other)
                  </label>
                  <TextField
                    name="final_diagnosis_other"
                    id="final_diagnosis_other"
                    className="dataentry_input"
                    variant="outlined"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    //disabled={!diagOtherChecked}
                    value={addBabyFinalData?.final_diagnosis_other}
                    onChange={handleAddFormChange}
                    InputProps={{
                      inputProps: {
                        maxLength: 250,
                        minLength: 1,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  width: "100%",
                  justifyContent: "center",
                  display: "flex",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  aria-label="add"
                  className="buttonStyle"
                  type="submit"
                >
                  Save {"&"} Next
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </LocalizationProvider>
    </React.Fragment>
  );
};

export default FinalDiagnosis;
