import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, Link, useNavigate } from "react-router-dom";
const ALPHA = /^[a-zA-Z ]*$/;
const Numerics = /^[0-9 ]*$/;
import { ToastContainer, toast } from "react-toastify";
import imageCompression from "browser-image-compression";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  Box,
  MenuItem,
  Dialog,
  Select,
  DialogContent,
  DialogTitle,
  Snackbar,
  Alert,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Back from "../../common/assets/images/Back.svg";
import Close from "../../common/assets/images/Close.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import "../../dataEntry/styles/DataEntry.scss";
import { margin } from "@mui/system";
import {
  getUserDetails,
  updateProfileData,
  ProfileFileUpload,
  setFormTab,
  changePassword,
  setErrorMessage,
} from "../reducers/profileDataThunk";
import { Buffer } from "buffer";
import { downloadReport } from "../../dataEntry/reducers/patientDataThunk";
import { PASSWORD_MISMATCH } from "../../dataEntry/utils/mockData";
import { TELUGU_STATES } from "../../Admin/utils/mockdata";

const AdminProfileScreen = () => {
  const user = useSelector((state) => state.user);
  const Profile = useSelector((state) => state.Profile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ProfileValue = Profile.profileDetail;

  const [profileData, setProfileData] = useState(ProfileValue);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [profilePic, setProfilePic] = useState("/broken-image.jpg");
  console.log('lllllllll',profileData)

  const [pwd, setPassword] = React.useState(false);
  const [NewPassword, setNewPassword] = React.useState(Profile.NewPassword);
  const [errorFields, setErrorFields] = useState({});
  const [showNameErrorMessage, setShowNameErrorMessage] = useState();
  const [showMobileErrorMessage, setShowMobileErrorMessage] = useState();
  const [showInstErrorMessage, setShowInstErrorMessage] = useState();
  const [showIANErrorMessage, setShowIANErrorMessage] = useState();
  const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState();
  const [showConfirmPasswordErrorMessage, setShowConfirmPasswordErrorMessage] =
    useState();
    const handleClickShowPassword = () => {
      setProfileData({ ...profileData, showPassword: !profileData.showPassword });
    };


  useEffect(() => {
    if (Profile.errorMessage) {
      setTimeout(() => {
        dispatch(setErrorMessage(""));
      }, 3000);
    }
  }, [Profile.errorMessage]);

  const verifyPrimaryContactNo = (event) => {
    const pincodeRegex = /^[6-9]\d{9}$/;
    if (!pincodeRegex.test(event.target.value)) {
      setShowMobileErrorMessage(
        "User Mobile Number Should be 10 Digits & It Should Starts From 6 to 9"
      );
      setErrorFields(Object.assign({}, errorFields, { contact_number: true }));
      handleAddFormChange(event);
    } else {
      handleAddFormChange(event);
      setErrorFields(Object.assign({}, errorFields, { contact_number: false }));
      setShowMobileErrorMessage("");
    }
  };

  const verifyPassword = (event) => {
    const Passwordcheck =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!Passwordcheck.test(event.target.value)) {
      setShowPasswordErrorMessage(
        "Password must contain a minimum of eight characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setErrorFields(Object.assign({}, errorFields, { newPassword: true }));
      FormChange(event);
    } else {
      FormChange(event);
      setErrorFields(Object.assign({}, errorFields, { newPassword: false }));
      setShowPasswordErrorMessage("");
    }
  };

  const verifyConfirmPassword = (event) => {
    const Passwordcheck =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!Passwordcheck.test(event.target.value)) {
      setShowConfirmPasswordErrorMessage(
        "Password must contain a minimum of eight characters, including at least one uppercase letter, one lowercase letter, one number, and one special character."
      );
      setErrorFields(
        Object.assign({}, errorFields, { confirm_password: true })
      );
      FormChange(event);
    } else {
      FormChange(event);
      setErrorFields(
        Object.assign({}, errorFields, { confirm_password: false })
      );
      setShowConfirmPasswordErrorMessage("");
    }
  };

  const userId = user?.userProfile?.user_id;
  const uploadProfilePic = async (event) => {
    setSelectedFiles(event.target.files);
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }
    let currentFile = event.target.files[0];
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1000,
      useWebWorker: true,
    };

      //let compressedFile = await imageCompression(currentFile, options);
      const compressedBlob = await imageCompression(currentFile, options);
      const compressedFile = new File([compressedBlob], currentFile.name, {
        type: compressedBlob.type,
      });
      console.log('lllllllllllllll',compressedFile)
    const formData = new FormData();
    const document_type = "ProfilePic";
    const userId = user?.userProfile?.user_id;
    formData.append("userId", userId);
    formData.append("document_type", document_type);
    formData.append("bmrn", profileData?.user_name);
    formData.append("file", compressedFile);
    const req = { userId, formData };
    dispatch(ProfileFileUpload({ formData, userId }))
      .unwrap()
      .then((resdata) => {
        fetchUserDetails();
        toast.success("Profile Pic updated Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }); 
        // console.log("The referal response data is", resdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const changePasswordData = () => {
    setPassword(true);
  };
  const handleClose = () => {
    setPassword(false);
  };
  const FormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...NewPassword };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);
    setNewPassword(newFormData);
  };

  const handleAddFormChange = (event) => {
    // console.log("We are in handle form change");
    const fieldname = event.target.name;
    // console.log("Field Name is ");
    // console.log(fieldname);
    const fieldvalue = event.target.value;
    // console.log("Field Value is ");
    // console.log(fieldvalue);
    const newFormData = { ...profileData };
    newFormData[fieldname] = fieldvalue;
    // console.log("Field Value is ");
    // console.log(newFormData);
    setProfileData(newFormData);
  };

  const FormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...NewPassword };
    if (newFormData.newPassword == newFormData.oldPassword) {
      toast.error("New Password Should be different from Old Password.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
      else{
      if (newFormData.newPassword == newFormData.confirm_password) {
        const req = { userId, newFormData };
        dispatch(changePassword(req)).then((resdata) => {
          // console.log("resdata", resdata);

          if (resdata.payload.status != "200") {
            toast.error(resdata.payload.message, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else {
            sessionStorage.removeItem("access_token");
            sessionStorage.removeItem("user");
            navigate("/login");
          }
        });
      } else {
        toast.error(PASSWORD_MISMATCH, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
  }
  };

  const HandleAddFormSubmit = (event) => {
    event.preventDefault();
    const newFormData = { ...profileData };
    setProfileData(newFormData);
    for (const key in newFormData) {
      if (!newFormData[key]) {
        newFormData[key] = "NA";
      }
    }
    const req = { userId, newFormData };
    dispatch(updateProfileData(req))
      .unwrap()
      .then((resdata) => {
        toast.success("Profile Updated Successfully.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        // console.log(resdata);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    if (userId) {
      fetchUserDetails();
    }
  }, [userId]);

  const fetchUserDetails = async () => {
    const req = { userId };
    dispatch(getUserDetails(req)).then((resdata) => {
      setProfileData(resdata.payload.response[0]);
    });
  };
  const encode = async (data) => {
    let buf = Buffer.from(data);
    let base64 = buf.toString("base64");
    return base64;
  };

  useEffect(async () => {
    if (profileData.profile_pic) {
      dispatch(downloadReport(profileData.profile_pic)).then(
        async (resdata) => {
          setProfilePic(
            `data:image/jpeg;base64,${await encode(resdata.payload.Body)}`
          );
        }
      );
    }
  }, [profileData.profile_pic]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Card variant="none">
        <CardContent>
          <form id="mother-medical-form" onSubmit={HandleAddFormSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10}>
                <h4 className="branch-header">My Profile</h4>
              </Grid>
            </Grid>
            <hr className="tag-line-border" />
            <Grid container>
              <Grid item xs={12} sm={1}></Grid>
              <Grid item xs={12} sm={3}>
                <Stack direction="row" spacing={2} className="profilepic">
                  <Avatar src={profilePic} sx={{ width: 150, height: 150 }} />
                </Stack>

                <Button
                  variant="contained"
                  component="label"
                  onChange={uploadProfilePic}
                  className="purple-add"
                  size="small"
                  style={{
                    marginTop: "20px",
                    margin: "20px auto",
                    width: "55%",
                  }}
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    id="btn-upload-referral"
                    name="btn-upload-referral"
                  />
                </Button>
              </Grid>

              <Grid item xs={12} sm={8}>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="Record ID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Name
                    </label>
                    <TextField
                      id="user_name"
                      name="user_name"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      onChange={handleAddFormChange}
                      value={profileData?.user_name}
                      errorText={showNameErrorMessage}
                      error={errorFields.user_name && showNameErrorMessage}
                      helperText={
                        errorFields.user_name && showNameErrorMessage
                          ? showNameErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (!ALPHA.test(event.key)) {
                          setShowNameErrorMessage(
                            "Only Alphabets and Space are acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, { user_name: true })
                          );
                          event.preventDefault();
                        } else {
                          handleAddFormChange(event);
                          setErrorFields(
                            Object.assign({}, errorFields, { user_name: false })
                          );
                          setShowNameErrorMessage("");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="Record ID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Email Address
                    </label>
                    <TextField
                      id="email_address"
                      name="email_address"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      onChange={handleAddFormChange}
                      value={profileData?.email_address}
                      disabled
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="contact_number"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Phone No
                    </label>
                    <TextField
                      id="contact_number"
                      name="contact_number"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      //onChange={handleAddFormChange}
                      value={profileData?.contact_number}
                      onChange={(e) => {
                        handleAddFormChange(e);
                        verifyPrimaryContactNo(e);
                      }}
                      error={
                        errorFields.contact_number && showMobileErrorMessage
                      }
                      //helperText={showErrorMessage}
                      errorText={showMobileErrorMessage}
                      helperText={
                        errorFields.contact_number && showMobileErrorMessage
                          ? showMobileErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (
                          !Numerics.test(event.key) &&
                          event.key != "Backspace"
                        ) {
                          event.preventDefault();
                          setShowMobileErrorMessage(
                            "Only Numerics are acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              contact_number: true,
                            })
                          );
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="Record ID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Password
                      <span
                        onClick={() => changePasswordData()}
                        className="changePassword"
                      >
                        Change Password
                      </span>
                    </label>
                    <TextField
                      id="password"
                      name="password"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      type={profileData.showPassword ? "text" : "password"}
                      
                      value={profileData.password}
                      onChange={handleAddFormChange}
                          InputProps={{
                            endAdornment: (
                              <IconButton
                                onClick={() => handleClickShowPassword()}
                              >
                                {profileData.showPassword ? (
                                  <VisibilityIcon />
                                ) : (
                                  <VisibilityOffIcon />
                                )}
                              </IconButton>
                            ),
                          }}
                      //type={"password"}
                      
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="Record ID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Institution Name
                    </label>
                    <TextField
                      id="institution_name "
                      name="institution_name"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      onChange={handleAddFormChange}
                      value={profileData?.institution_name}
                      error={
                        errorFields.Institution_name && showInstErrorMessage
                      }
                      //helperText={showErrorMessage}
                      errorText={showInstErrorMessage}
                      helperText={
                        errorFields.Institution_name && showInstErrorMessage
                          ? showInstErrorMessage
                          : ""
                      }
                      required
                      onKeyDown={(event) => {
                        if (!ALPHA.test(event.key)) {
                          setShowInstErrorMessage(
                            "Only Alphabets and Space are acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              Institution_name: true,
                            })
                          );
                          event.preventDefault();
                        } else {
                          handleAddFormChange(event);
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              Institution_name: false,
                            })
                          );
                          setShowInstErrorMessage("");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="Record ID"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      Institution Alias Name
                    </label>
                    <TextField
                      id="institution_alias_name "
                      name="institution_alias_name"
                      label=""
                      variant="outlined"
                      className="dataentry_input"
                      size="small"
                      onChange={handleAddFormChange}
                      value={profileData?.institution_alias_name}
                      error={
                        errorFields.institution_alias_name &&
                        showIANErrorMessage
                      }
                      //helperText={showErrorMessage}
                      errorText={showIANErrorMessage}
                      helperText={
                        errorFields.institution_alias_name &&
                        showIANErrorMessage
                          ? showIANErrorMessage
                          : ""
                      }
                      onKeyDown={(event) => {
                        if (!ALPHA.test(event.key)) {
                          setShowIANErrorMessage(
                            "Only Alphabets and Space are acceptable"
                          );
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              institution_alias_name: true,
                            })
                          );
                          event.preventDefault();
                        } else {
                          handleAddFormChange(event);
                          setErrorFields(
                            Object.assign({}, errorFields, {
                              institution_alias_name: false,
                            })
                          );
                          setShowIANErrorMessage("");
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <label
                      htmlFor="State"
                      className="dataentry_label"
                      style={{ display: "block" }}
                    >
                      State
                    </label>
                    <Select
                      labelId="state"
                    id="state"
                    name="state"
                    label=""
                    onChange={handleAddFormChange}
                    value={profileData.state}
                    size="small"
                    className="dataentry_select_edu"
                    >
                      {TELUGU_STATES.map((type) => (
                        <MenuItem value={type}>{type}</MenuItem>
                      ))}
                    </Select>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div
              style={{
                justifyContent: "flex-end",
                display: "flex",
                marginTop: "20px",
                marginRight: "40px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                className="buttonStyle sign-up-button"
                type="submit"
              >
                Update User
              </Button>
            </div>
            {/*  <Box sx={{ "& > :not(style)": { m: 1 } }}>
              <Button
                variant="outlined"
                color="primary"
                aria-label="add"
                className="floating-btn"
                type="submit"
              >
                Update User
              </Button>
            </Box> */}
          </form>
        </CardContent>
      </Card>
      <Dialog open={pwd} className="dialogWidth">
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "0.8px solid",
            borderColor: "rgba(101, 114, 228, 0.4)",
          }}
        >
          <span>
          <Link
            className="FIle-left"
            onClick={handleClose}
            style={{ marginRight: "20px" }}
          >
            <img
              src={Back}
              className="file-img-left"
              alt=""
              style={{ width: "12px" }}
            />
          </Link>
          Change Password
          </span>
          <Link className="FIle-right-extreme" onClick={handleClose}>
            <img src={Close} alt="" style={{ marginRight: "20px" }}/>
          </Link>
        </DialogTitle>
        <DialogContent>
          <form iid="mother-medical-form" onSubmit={FormSubmit} >
            <Grid container>
              <Grid item xs={12} sm={12}>
                <label
                  htmlFor="Old password"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Old Password
                </label>
                <TextField
                  id="oldPassword"
                  name="oldPassword"
                  label=""
                  variant="outlined"
                  className="dataentry_input2"
                  size="small"
                  type={"password"}
                  onChange={FormChange}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <label
                  htmlFor="New Password"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  New Password
                </label>
                <TextField
                  id="newPassword"
                  name="newPassword"
                  label=""
                  variant="outlined"
                  className="dataentry_input2"
                  size="small"
                  type={"password"}
                  onChange={(e) => {
                    FormChange(e);
                    verifyPassword(e);
                  }}
                  error={errorFields.newPassword && showPasswordErrorMessage}
                  errorText={showPasswordErrorMessage}
                  helperText={
                    errorFields.newPassword && showPasswordErrorMessage
                      ? showPasswordErrorMessage
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <label
                  htmlFor="Confirm New Password"
                  className="dataentry_label"
                  style={{ display: "block" }}
                >
                  Confirm New Password
                </label>
                <TextField
                  id="confirm_password"
                  name="confirm_password"
                  label=""
                  variant="outlined"
                  className="dataentry_input2"
                  size="small"
                  type={"password"}
                  //onChange={FormChange}

                  onChange={(e) => {
                    FormChange(e);
                    verifyConfirmPassword(e);
                  }}
                  error={
                    errorFields.confirm_password &&
                    showConfirmPasswordErrorMessage
                  }
                  errorText={showConfirmPasswordErrorMessage}
                  helperText={
                    errorFields.confirm_password &&
                    showConfirmPasswordErrorMessage
                      ? showConfirmPasswordErrorMessage
                      : ""
                  }
                />
              </Grid>
            </Grid>
            <div className="formSection">
              <Grid container>
                <Grid item xs={12} sm={2}>
                  <Button
                    variant="contained"
                    className="purple-add"
                    type="submit"
                  >
                    <span>Save</span>
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        </DialogContent>
      </Dialog>
      {Profile.errorMessage && (
        <Snackbar
          open={Profile.errorMessage}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert severity="error" variant="filled">
            {Profile.errorMessage}
          </Alert>
        </Snackbar>
      )}
    </React.Fragment>
  );
};

export default AdminProfileScreen;
