import { createSlice } from "@reduxjs/toolkit";
import * as PatientThunk from "./patientDataThunk";
import { initialState } from "./InitialState";

const patientScoreSlice = createSlice({
  name: "patientScore",
  initialState,
  extraReducers: {
   
    [PatientThunk.getBabyAppearByReadingIdScore.fulfilled]: (state, action) => {
      state.babyAppearanceScore = action.payload.response;
    },

    [PatientThunk.getBabyProfileScore.fulfilled]: (state, action) => {
      state.BabyProfileScore = action.payload.response;
    },
    [PatientThunk.getMotherProfileScore.fulfilled]: (state, action) => {
      state.motherProfileScore = action.payload.response;
    },
    [PatientThunk.getBabyRespiratoryByReadingIdScore.fulfilled]: (state, action) => {
      state.babyRespiratoryDataScore = action.payload.response;
    },
    [PatientThunk.getBabyCNSDataByReadingIdScore.fulfilled]: (state, action) => {
      state.babyCNSDataScore = action.payload.response;
    },
    [PatientThunk.getBabyCVFByReadingIdScore.fulfilled]: (state, action) => {
      state.babyCVDataScore = action.payload.response;
    },
    [PatientThunk.getBabyGITByReadingIdScore.fulfilled]: (state, action) => {
      state.babyGITDataScore = action.payload.response;
    },
    [PatientThunk.getBabyInvDataByReadingIdScore.fulfilled]: (state, action) => {
      console.log('action.payload.response',action.payload.response)
      state.babyInvDataScore = action.payload.response;
    },
  },
});

export default patientScoreSlice.reducer;

