import React from "react";
import Home from "../common/components/Home";
//import Login from "../login/components/Login";
import Branch from "../login/components/loginWelcome";
import Login from "../common/components/Login";
import { ALL } from "../common/utils/consts";
import DataEntry from "../dataEntry/components/DataEntry";
import Admin from "../Admin/components/Admin";
import UserHeader from "../Admin/components/UserHeader";
import ReferralHeader from "../Admin/components/ReferralHeader";
import RequireAuth from "./RequireAuth";
import SignUp from "../signUp/components/signup";
import AccCreation from "../signUp/components/accountCreation";
import LoginScreen from "../login/components/loginScreen";
import Dashboard from "../Dashboard/components/Dashboard";
import SubscPopUp from "../signUp/components/SubscriptionPopup";
import VerifyAccount from "../signUp/components/verify-account";
import EmailVerify from "../signUp/components/emailVerify";
import EmailVerifyMessage from "../signUp/components/refDrVerifyMessage";
import ForgotPassword from "../login/components/forgotpwd";
import AdminVerification from "../signUp/components/adminVerification";
import ForgotOTP from "../login/components/forgotOTP";
import LoginOTP from "../login/components/LoginOTP";
import NewPassword from "../login/components/newpassword";
import DoctorOpinion from "../dataEntry/components/DrOpinion";
import AddBMRN from "../dataEntry/components/AddBMRN";
import NewBMRN from "../dataEntry/components/NewBmrn";
import AdminProfile from "../Profile/components/AdminProfile";
import NewPwd from "../Profile/components/newPassword";
import AdminAccess from "../Profile/components/AdminAccess";
import ReportsDashboard from "../ReportsDashboard/components/ReportsDashboard";
import Reports from "../ReportsDashboard/components/Reports";
import ReadingVsSepsis from "../ReportsDashboard/components/ReadingVsSepsicScore";
import GenderDistribution from "../ReportsDashboard/components/GenderDistribution";
import FinalVsPreliminary from "../ReportsDashboard/components/FinalVsPreliminary";
import AddOpinion from "../dataEntry/components/SendOpinion";
import PrescDoc from "../dataEntry/components/PrescDocPrint";
import PatientDetails from "../dataEntry/components/PtDetails";
import AdminListing from "../Admin/components/ward/AdminListing";
import ReferralDoctor from "../Admin/components/ReferralDoctor";
import PrivacyPolicy from "../login/components/privacyPolicy";

export const appRoutes = [
  {
    path: "/",
    element: <Home />,
    children: [
      {
        index: true,
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <Dashboard />
          </RequireAuth>
        ),
      },
      {
        path: "/login",
        element: <LoginScreen />,
      },
      {
        path: "/dataentry",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <DataEntry />
          </RequireAuth>
        ),
      },

      {
        path: "/AddBMRN",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <AddBMRN />
          </RequireAuth>
        ),
      },

      {
        path: "/NewBMRN",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <NewBMRN />
          </RequireAuth>
        ),
      },

      {
        path: "/ReadingVsSepsis",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <ReadingVsSepsis />
          </RequireAuth>
        ),
      },

      {
        path: "/GenderDistribution",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <GenderDistribution />
          </RequireAuth>
        ),
      },

      {
        path: "/FinalVsPreliminary",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <FinalVsPreliminary />
          </RequireAuth>
        ),
      },

      {
        path: "/AdminProfile",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <AdminProfile />
          </RequireAuth>
        ),
      },

      {
        path: "/ReportsDashboard",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <ReportsDashboard />
          </RequireAuth>
        ),
      },

      {
        path: "/Reports",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <Reports />
          </RequireAuth>
        ),
      },

      {
        path: "/NewPwd",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <NewPwd />
          </RequireAuth>
        ),
      },
      {
        path: "/AdminAccess",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <AdminAccess />
          </RequireAuth>
        ),
      },

      {
        path: "/admin",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <Admin />
          </RequireAuth>
        ),
      },

      {
        path: "/UserHeader",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <UserHeader />
          </RequireAuth>
        ),
      },

      {
        path: "/AdminListing",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <AdminListing />
          </RequireAuth>
        ),
      },

      {
        path: "/ReferralDoctor",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <ReferralDoctor />
          </RequireAuth>
        ),
      },

      {
        path: "/ReferralHeader",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <ReferralHeader />
          </RequireAuth>
        ),
      },
      {
        path: "/Branch",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <Branch />
          </RequireAuth>
        ),
      },
      {
        path: "/AccCreation",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <AccCreation />
          </RequireAuth>
        ),
      },

      {
        path: "/Dashboard",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <Dashboard />
          </RequireAuth>
        ),
      },

      {
        path: "/PrescDoc/",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <PrescDoc />
          </RequireAuth>
        ),
      },

      {
        path: "/DrPrescription/",
        element: (
          <RequireAuth requireLogin={true} roles={[ALL]}>
            <DoctorOpinion />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/signup",
    element: <SignUp />,
  },

  {
    path: "/ForgotPassword",
    element: <ForgotPassword />,
  },
  {
    path: "/verify-account/:id",
    element: <VerifyAccount />,
  },

  {
    path: "/ForgotOTP",
    element: <ForgotOTP />,
  },

  {
    path: "/LoginOTP",
    element: <LoginOTP />,
  },

  {
    path: "/reset-password/:id",
    element: <NewPassword />,
  },

  {
    path: "/email-verification/:id",
    element: <EmailVerify />,
  },
  {
    path: "/email-verify-success",
    element: <EmailVerifyMessage />,
  },
  {
    path: "/admin-email-verification/:id",
    element: <AdminVerification />,
  },
  {
    path: "/SubscPopUp",
    element: <SubscPopUp />,
  },

  {
    path: "/add-opinion/:id",
    element: <AddOpinion />,
  },
  {
    path: "/PatientDetails",
    element: <PatientDetails />,
  },
];
