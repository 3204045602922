import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { ALL } from "../common/utils/consts";

const RequireAuth = (props) => {
  let location = useLocation();
  const user = useSelector((state) => state.user.userProfile);
  const role = user.role;

  if ((props.roles.includes(ALL) || props.roles.includes(role)) || (!props.requireLogin || user.username)) {
    return props.children;
  }
  return <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;