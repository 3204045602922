import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import Close from "../../common/assets/images/Close.svg";
import { Link } from "react-router-dom";
import NotificationView from "../../Dashboard/components/NotificationView";
const NotificationPdf = (props) => {
  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="lg"
        open={props.open}
        onClose={() => setDialogOpen(false)}
        style={{ width: "auto" }}
        className="report-dialog"
      >
        <DialogTitle style={{ borderBottom: "0.8px solid #f0f1fc" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{color:'#fff'}}>{props.title}</div>
            <div>
              <Link
                className="FIle-right-extreme"
                onClick={() => props.action(false)}
              >
                <img
                  src={Close}
                  style={{ paddingLeft: 418 }}
                  className="file-img-right-extreme"
                  alt=""
                />
              </Link>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <NotificationView data={props.data} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default NotificationPdf;
