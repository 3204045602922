import React from "react";
import { Outlet, Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HelpImg from "../assets/images/components/HelpImg";
import AddHomeOutlinedIcon from "@mui/icons-material/AddHomeOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import Logo from "../assets/images/logo.png";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import LocalHospitalOutlinedIcon from "@mui/icons-material/LocalHospitalOutlined";
import InsertChartOutlinedRoundedIcon from "@mui/icons-material/InsertChartOutlinedRounded";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";

import { logout } from "../../login/reducers/loginThunk";

import { getbmrnlist } from "../../dataEntry/reducers/patientDataThunk";
import { useState } from "react";
import { useEffect } from "react";

const Layout = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(false);

  const user = useSelector((state) => state.user);
  const userdata = sessionStorage.getItem("user");
  const allowedPermissions = userdata ? JSON.parse(userdata).permissions : {};
  const subScription = user.subScriptionStatus?.isSubscribed;
  console.log('lllllllllllll',allowedPermissions)

  useEffect(() => {
    setTimeout(() => {
      const userdata = sessionStorage.getItem("user");
      setIsAdmin(userdata ? JSON.parse(userdata).is_admin : false);
      //setPermissions(userdata ? JSON.parse(userdata).is_admin : false);
    }, 1000);
  }, []);

  const dashboard = () =>{

    navigate("/Dashboard");
    window.location.reload();
  }

  const logout = (e) => {
    e.preventDefault();
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user");
    navigate("/login");
    window.location.reload();
  };
  const dataentryscreen = (e) => {
    dispatch(getbmrnlist());
    navigate("/AddBMRN");
  };
  return (
    <>
      <nav className="nav-position">
        <ul className="sidebar-ul">
          <li className="sidebar-menuitems">
            <Link to="/" className="sidenavbar">
              <img className="sidenavbar-logo" src={Logo} />
            </Link>
          </li>
          <li
            className={
              splitLocation[1] === "Dashboard" ? "active" : "nonactive"
            }
          >
            <Link to="/Dashboard" className="sidenavbar" onClick={dashboard}>
              <GridViewOutlinedIcon className="nav_fill" fontSize="large" />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Dashboard
              </h6>
            </Link>
          </li>
          {allowedPermissions &&
          allowedPermissions.bmrn ? (
          <li
            className={
              splitLocation[1] === "dataentry" ||
              splitLocation[1] === "NewBMRN" ||
              splitLocation[1] === "AddBMRN" || 
              splitLocation[1] === "DrPrescription"
                ? "active"
                : "nonactive"
            }
          >
            {!subScription ? (
              <span className="sidenavbar">
                <FileCopyOutlinedIcon fontSize="large" />
                <h6
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    fontSize: "10px",
                    marginTop: "-5px",
                    opacity: "30%",
                  }}
                >
                  Data Entry
                </h6>
              </span>
            ) : (
              <Link
                to="/NewBMRN"
                className="sidenavbar"
                onClick={dataentryscreen}
              >
                <FileCopyOutlinedIcon
                  fontSize="large"
                  className="nav_fill"
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                  }}
                />
                <h6
                  className="nav_fill"
                  style={{
                    padding: "0px 0px",
                    margin: "0px 0px",
                    fontSize: "10px",
                    marginTop: "-5px",
                  }}
                >
                  Data Entry
                </h6>
              </Link>
            )}
          </li>
          ) : null}
          {allowedPermissions &&
          allowedPermissions.hospital ? (
            <li
              className={splitLocation[1] === "admin" ? "active" : "nonactive"}
            >
              {!subScription ? (
                <span className="sidenavbar">
                  <AddHomeOutlinedIcon fontSize="large" />
                  <h6
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                      fontSize: "10px",
                      marginTop: "-5px",
                      opacity: "30%",
                    }}
                  >
                    Hospital <br />
                    Management
                  </h6>
                </span>
              ) : (
                <Link to="/admin" className="sidenavbar">
                  <AddHomeOutlinedIcon className="nav_fill" fontSize="large" />
                  <h6
                    className="nav_fill"
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                      fontSize: "10px",
                      marginTop: "-5px",
                    }}
                  >
                    Hospital <br />
                    Management
                  </h6>
                </Link>
              )}
            </li>
          ) : null}

          {allowedPermissions &&
          allowedPermissions.user ? (
            <li
              className={
                splitLocation[1] === "AdminListing" ? "active" : "nonactive"
              }
            >
              {!subScription ? (
                <span className="sidenavbar">
                  <PersonAddOutlinedIcon fontSize="large" />
                  <h6
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                      fontSize: "10px",
                      marginTop: "-5px",
                      opacity: "30%",
                    }}
                  >
                    User <br />
                    Management
                  </h6>
                </span>
              ) : (
                <Link to="/AdminListing" className="sidenavbar">
                  <PersonAddOutlinedIcon
                    className="nav_fill"
                    fontSize="large"
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                    }}
                  />
                  <h6
                    className="nav_fill"
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                      fontSize: "10px",
                      marginTop: "-5px",
                    }}
                  >
                    User <br />
                    Management
                  </h6>
                </Link>
              )}
            </li>
          ) : null}
          {allowedPermissions &&
          allowedPermissions.referral_doctor ? (
            <li
              className={
                splitLocation[1] === "ReferralDoctor" ? "active" : "nonactive"
              }
            >
              {!subScription ? (
                <span className="sidenavbar">
                  <LocalHospitalOutlinedIcon fontSize="large" />
                  <h6
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                      fontSize: "10px",
                      marginTop: "-5px",
                      opacity: "30%",
                    }}
                  >
                    Referral <br />
                    Management
                  </h6>
                </span>
              ) : (
                <Link to="/ReferralDoctor" className="sidenavbar">
                  <LocalHospitalOutlinedIcon
                    className="nav_fill"
                    fontSize="large"
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                    }}
                  />
                  <h6
                    className="nav_fill"
                    style={{
                      padding: "0px 0px",
                      margin: "0px 0px",
                      fontSize: "10px",
                      marginTop: "-5px",
                    }}
                  >
                    Referral <br />
                    Management
                  </h6>
                </Link>
              )}
            </li>
          ) : null}
          {allowedPermissions &&
            allowedPermissions.reports && (
              <li
                className={
                  splitLocation[1] === "ReportsDashboard" ||
                  splitLocation[1] === "Reports"
                    ? "active"
                    : "nonactive"
                }
              >
                {!subScription ? (
                  <span className="sidenavbar">
                    <InsertChartOutlinedRoundedIcon fontSize="large" />
                    <h6
                      style={{
                        padding: "0px 0px",
                        margin: "0px 0px",
                        fontSize: "10px",
                        marginTop: "-5px",
                        opacity: "30%",
                      }}
                    >
                      Reports
                    </h6>
                  </span>
                ) : (
                  <Link to="/Reports" className="sidenavbar">
                    <InsertChartOutlinedRoundedIcon
                      fontSize="large"
                      className="nav_fill"
                      style={{
                        padding: "0px 0px",
                        margin: "0px 0px",
                      }}
                    />
                    <h6
                      className="nav_fill"
                      style={{
                        padding: "0px 0px",
                        margin: "0px 0px",
                        fontSize: "10px",
                        marginTop: "-5px",
                      }}
                    >
                      Reports
                    </h6>
                  </Link>
                )}
              </li>
            )}
          <li className={splitLocation[1] === "/" ? "active" : "nonactive"}>
            <Link to="/" className="sidenavbar" onClick={logout}>
              <LogoutOutlinedIcon
                fontSize="large"
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                }}
              />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Logout
              </h6>
            </Link>
          </li>
          {/*  <li className={splitLocation[1] === "/" ? "active" : "nonactive"}>
            <Link to="/" className="sidenavbar">
              <HelpOutlineRoundedIcon
                fontSize="large"
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                }}
              />
              <h6
                className="nav_fill"
                style={{
                  padding: "0px 0px",
                  margin: "0px 0px",
                  fontSize: "10px",
                  marginTop: "-5px",
                }}
              >
                Help
              </h6>
            </Link>
          </li> */}
        </ul>
      </nav>
    </>
  );
};
export default Layout;
